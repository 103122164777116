/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import Request from '../request';

const CONSTANTS = {
    GET: {
        SUCCESS: 'swagger_actions.get.success'
    }
};

class SwaggerActions {
    get() {
        Request.get('doc/source').then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                spec: Immutable.fromJS(res.body)
            });
        }).catch( err => {
            throw err;
        });
    }
}

let actions = new SwaggerActions();

export {
    actions as SwaggerActions,
    CONSTANTS as SwaggerConstants
};
