/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';

const CONSTANTS = {
    CAPTIONS: {
        TOGGLE: 'titles.merchandise.captions.toggle'
    },

    CLEAR: 'titles.merchandise.clear',

    GALLERY: {
        CLOSE: 'titles.merchandise.gallery.close',
        OPEN: 'titles.merchandise.gallery.open'
    },

    MERCHANDISE: {
        GET: {
            SUCCESS: 'titles.merchandise.image.get.success'
        }
    }
};

class MerchandiseActions {
    constructor() {
        // Map to store all requests.
        this.requests = {};
    }

    clear() {
        // Abort all pending requests if any.
        Object.keys(this.requests).forEach(reqId => {
            this.requests[reqId].abort();
            delete this.requests[reqId];
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
        return;
    }

    closeGallery() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GALLERY.CLOSE
        });

        return;
    }

    getByIds(ids) {
        ids.forEach(id => {
            // Save the request in a requests array, so that it can be
            // aborted if the user navigates away.
            let url = `asset/merchandise/${id}`;
            let req = Request.get(url);
            this.requests[url] = req;

            req.then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.GET.SUCCESS,
                    merchandise: Immutable.fromJS(res.body)
                });
            });

            return;
        });

        return;
    }

    openGallery(image) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GALLERY.OPEN,
            image
        });

        return;
    }

    toggleCaptions() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CAPTIONS.TOGGLE
        });

        return;
    }
}

let actions = new MerchandiseActions();

export {
    actions as MerchandiseActions,
    CONSTANTS as MerchandiseConstants
};
