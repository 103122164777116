/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HomeConstants} from './home-actions';
import {Dispatcher} from '../flux-helpers';

class HomeStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            homepage: Immutable.Map(),
            images: Immutable.Map(),
            newThisWeek: Immutable.Map(),
            playlistVideoIndex: 0,
            titles: Immutable.Map(),
            videos: Immutable.Map()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HomeConstants.CLEAR_HOMEPAGE:
            state = state.set('homepage', Immutable.Map());
            break;
        case HomeConstants.GET.SUCCESS:
            state = state.set('homepage', action.homepage);
            break;

        case HomeConstants.IMAGE.GET.SUCCESS:
            state = state.setIn(['images', action.id], action.image);
            break;

        case HomeConstants.NEW_THIS_WEEK.GET.SUCCESS:
            state = state.set('newThisWeek', action.newThisWeek);
            break;

        case HomeConstants.TITLE.GET.SUCCESS:
            state = state.setIn(['titles', action.id], action.title);
            break;

        case HomeConstants.VIDEO.GET.SUCCESS:
            state = state.setIn(['videos', action.id], action.video);
            break;

        case HomeConstants.VIDEO.TITLES.GET.SUCCESS:
            state = state.updateIn(['videos', action.id], video => {
                return video.merge({
                    titles: action.titles,
                });
            });
            break;

        case HomeConstants.VIDEO.STREAMS.GET.SUCCESS:
            state = state.updateIn(['videos', action.videoId], video => {
                return video.merge({
                    drmToken: action.token,
                    streams: [action.streams]
                });
            });
            state = state.set('playlistVideoIndex', action.videoIndex);
            break;
        }
        return state;
    }
}

export default new HomeStore(Dispatcher);
