/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DigitalSalesDecksConstants} from './dsd-actions';
import {Dispatcher} from '../flux-helpers';

class DigitalSalesDecksStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            loading: false,
            titleId: '',
            titles: Immutable.List(),
            titleVideos: Immutable.Map()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case DigitalSalesDecksConstants.GET.ERROR:
            state = state.set('loading', false);
            break;

        case DigitalSalesDecksConstants.GET.START:
            state = state.set('loading', true);
            break;

        case DigitalSalesDecksConstants.GET.SUCCESS:
            let titleId = action.titles.getIn(['0', 'id']);
            state = state.merge({
                loading: false,
                titles: action.titles,
                titleId: titleId
            });
            break;

        case DigitalSalesDecksConstants.VIDEOS.ADD:
            state = state.setIn(['titleVideos', action.video.get('titleId')], action.video);
            break;

        case DigitalSalesDecksConstants.GET_VIDEO_STREAMS:
            state.get('titles').map((title, i) => {
                if (title.get('id') === action.titleId) {
                    state = state.mergeIn(['titles', i, 'video'], {
                        drmToken: action.token,
                        id: action.videoId,
                        streams: [action.streams]
                    });
                }
            });
            break;

        case DigitalSalesDecksConstants.TALENTS_BY_TITLE.GET.SUCCESS:
            state.get('titles').map((title, i) => {
                const talentsByTitle = action.talentData.find(data => data.get('titleId') === title.get('id'));
                if (talentsByTitle && !talentsByTitle.isEmpty()) {
                    state = state.mergeIn(['titles', i, 'talent'], talentsByTitle.get('talent'));
                }
            });
        }

        return state;
    }
}

export default new DigitalSalesDecksStore(Dispatcher);
