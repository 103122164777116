/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Dispatcher} from '../../flux-helpers';

let CONSTANTS = {
    SEARCHBAR: {
        ACTIONS: {
            FIX: 'searchBar.actions.fix',
            UNFIX: 'searchBar.actions.unfix',
            CHANGE: 'searchBar.actions.change'
        }
    },
    SEARCHICON: {
        ACTIONS: {
            SHOW: 'searchIcon.actions.show',
            HIDE: 'searchIcon.actions.hide'
        }
    }
};

class PageLayoutActions {

    showSearchBar() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCHBAR.ACTIONS.FIX
        });
    }

    hideSearchBar() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCHBAR.ACTIONS.UNFIX
        });
    }

    showSearchIcon() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCHICON.ACTIONS.SHOW
        });
    }

    hideSearchIcon() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCHICON.ACTIONS.HIDE
        });
    }

    changeSearchBarDropdown(filter) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCHBAR.ACTIONS.CHANGE,
            assetFilter: filter
        });
    }

}

let actions = new PageLayoutActions();
export {
    actions as PageLayoutActions,
    CONSTANTS as PageLayoutConstants
};
