/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SubscriptionConstants} from './subscription-actions';
import {Dispatcher} from '../../../flux-helpers';

class SubscriptionStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            subscriptions: Immutable.Map()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case SubscriptionConstants.CLEAR:
            state = this.getInitialState();
            break;

        case SubscriptionConstants.GET.SUCCESS:
            if (state.get('subscriptions').isEmpty()) {
                // if there are no previously-fetched subscriptions, set and return immediately
                state = state.set('subscriptions', action.subscriptions);
                break;
            }

            // if we already had fetched subscriptions, new data that arrives must be merged with what we already have
            let subscriptions = state.get('subscriptions').toJS();
            let toBeMerged = action.subscriptions.toJS();

            for (let subscriptionContentType in toBeMerged) {
                if (toBeMerged[subscriptionContentType].elements.length > 0) {
                    // assign only if received object has updated keys for the current subscription
                    // (this way, we keep previous fetched subscriptions unaltered)
                    subscriptions[subscriptionContentType] = toBeMerged[subscriptionContentType];
                }
            }
            state = state.set('subscriptions', Immutable.fromJS(subscriptions));

            break;

        case SubscriptionConstants.UPDATE_SUBSCRIPTION:
            if (action.value) {
                const subscription = Immutable.Map({
                    titleId: action.titleId,
                    userId: action.userId,
                    suggestedByUserId: action.suggestedByUserId,
                    subscriptionContentType: action.subscriptionContentTypeId
                });

                state = state.setIn(['subscriptions', action.subscriptionContentTypeId.toString()], Immutable.fromJS({
                    elements: [subscription],
                    totalCount: 1,
                    offset: 0
                }));
            } else {
                state = state.setIn(['subscriptions', action.subscriptionContentTypeId.toString()], Immutable.fromJS({
                    elements: [],
                    totalCount: 0,
                    offset: 0
                }));
            }
            break;
        case SubscriptionConstants.UPDATE_SUBSCRIPTION_USER:
            let nextSubscriptions = {};
            Object.keys(state.get('subscriptions').toJS()).forEach(ct => {
                const s = state.getIn(['subscriptions', ct.toString()]).toJS();

                if (s?.elements?.length) {
                    s.elements = s.elements.map(e => {
                        return Object.assign({}, e, {
                            userId: action.userId,
                            suggestedByUserId: action.suggestedByUserId,
                        });
                    });
                }
                nextSubscriptions[ct] = s;
            });
            state = state.set('subscriptions', Immutable.fromJS(nextSubscriptions));

            break;

        case SubscriptionConstants.UNSUBSCRIBE.CLEAR:
            state = state.delete('toUnsubscribeClient');
            break;

        case SubscriptionConstants.UNSUBSCRIBE.SET:
            state = state.set('toUnsubscribeClient', {
                subscriptionContentType: action.subscriptionContentType,
                userId: action.userId,
                titleId: action.titleId,
                sortFieldName: action.sortFieldName,
                sortDirection: action.sortDirection,
                size: action.size,
                offset: action.offset
            });
            break;

        }

        return state;
    }
}

export default new SubscriptionStore(Dispatcher);
