/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {DownloadActions, DownloadConstants} from '../common/download/download-actions';
import config from '../config/config.js';
import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

// Require for Proper Timezone Display
require('moment-timezone');
let configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ADD_TITLES: 'work_order_constants.add_titles',
    CLEAR: 'work_order_constants.clear',
    DOWNLOAD: {
        ERROR: 'work_order_constants.download.error'
    },
    EXPORT_SPECIFICATIONS_BY_NAME: {
        GENERIC: 1,
        NETFLIX: 2,
        MASS_UPDATE_TEMPLATE: 3,
        AMAZON: 4,
        HULU: 5
    },
    EXPORT_TYPES: {
        EXCEL: 'Excel',
        JSON: 'Json'
    },
    GET: {
        ERROR: 'work_order_constants.get.error',
        START: 'work_order_constants.get.start',
        SUCCESS: 'work_order_constants.get.success'
    },
    REMOVE_TITLE: 'work_order_constants.remove_title',
    REORDER: 'work_order_constants.reorder',
    SAVE: {
        SUCCESS: 'work_order_constants.save.success',
        START: 'work_order_constants.save.start',
        ERROR:'work_order_constants.save.error'
    },
    STATUS: {
        1: 'Active',
        2: 'Complete',
        3: 'Canceled',
        4: 'On hold',
        5: 'Archived'
    },
    STATUS_BY_NAME: {
        ACTIVE: 1,
        COMPLETE: 2,
        CANCELED: 3,
        ONHOLD: 4,
        ARCHIVED: 5
    },
    STATUS_TYPES: [
        {id:1, name: 'Active'},
        {id:2, name: 'Complete'},
        {id:3, name: 'Canceled'},
        {id:4, name: 'On Hold'},
        {id:5, name: 'Archived'}
    ],
    UPDATE: 'work_order_constants.update'
};

const TITLE_BATCH = 20;

class WorkOrderActions {

    addTitles(ids, workOrderId) {
        let batches = [];
        let redirectUrl = 'packages';
        if (workOrderId !== undefined) {
            redirectUrl = `packages/${workOrderId}`;
        }
        while (ids.length) {
            batches.push(ids.splice(0, TITLE_BATCH));
        }
        return Promise.all(
            batches.map(r => Request.get('title').query({'title-id': r}))
        ).then(titles => {
            titles = titles.reduce( (arr, r) => {
                return arr.concat(r.body.results);
            }, []);
            let titlesToAdd = titles.map(t => {
                let title = {
                    category: t.category,
                    fullRunningOrder: t.fullRunningOrder,
                    mpmNumber: t.mpmNumber,
                    parentTitleDisplayName: t.parentTitleDisplayName,
                    runningOrder: t.runningOrder,
                    titleDisplayName: t.name,
                    titleId: t.id,
                    titleReleaseDate: t.titleReleaseDate
                };
                return title;
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.ADD_TITLES,
                titlesToAdd: Immutable.fromJS(titlesToAdd)
            });
            RouterActions.redirect(redirectUrl);
        }).catch(err => {
            NotificationActions.showAlertDanger('work-orders.add-titles.error');
            throw err;
        });
    }

    batchMPMs(workOrderId, mpmNumbers) {
        let separatorRegex = /[.:,;_-\s\r\n|]+/;
        mpmNumbers = mpmNumbers.split(separatorRegex);

        Request.post(`work-order/${workOrderId}/validate-batch-mpm`)
            .send(mpmNumbers)
            .then(response => {
                const validations = response.body;

                let nonValidNumbers = [], validNumbers = [];
                validations.forEach(validation => {
                    if (validation.isValid) {
                        validNumbers.push(validation.mpmNumber);
                    } else {
                        nonValidNumbers.push(validation.mpmNumber);
                    }
                });

                if (nonValidNumbers.length) {
                    NotificationActions.showAlertDanger('work-orders.batch-mpm.validate.error', false, nonValidNumbers.join(', '));
                }

                if (validNumbers.length) {
                    Request.post(`work-order/${workOrderId}/batch-mpm`)
                        .send(validNumbers)
                        .then(() => {
                            this.get(workOrderId, null, false);
                            NotificationActions.showAlertSuccess('work-orders.batch-mpm.success', false, validNumbers.join(', '));
                            return;
                        })
                        .catch(err => {
                            NotificationActions.showAlertDanger('work-orders.batch-mpm.error', false, validNumbers.join(', '));
                            throw err;
                        });
                    return;
                }
            })
            .catch(/* istanbul ignore next */err => {
                NotificationActions.showAlertDanger('work-orders.validate-batch-mpm.error');
                throw err;
            });

        return;
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(id, titlesToAdd, updateWorkOrder = true) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });
        Promise.all([
            Request.get(`work-order/${id}`),
            Request.get(`work-order/${id}/title`)
        ]).spread((workOrderRes, workOrderTitlesExport) => {
            let wo = Immutable.fromJS(workOrderRes.body);

            let ordered = wo.get('workOrderTitles').sortBy(wot => wot.get('displayOrder'));
            let idToPosition = ordered.reduce((b, elem, i) => {
                b[elem.get('titleId')] = i;
                return b;
            }, {});

            let woe = Immutable.fromJS(workOrderTitlesExport.body.workOrderTitlesExport);
            /* istanbul ignore if */
            if (!woe) {
                woe = Immutable.List();
            }
            // FIXME: remove sort when API sorts things the right way.
            /* istanbul ignore next */
            woe = woe.sort((a, b) => {
                let posA = idToPosition[a.get('titleId')];
                let posB = idToPosition[b.get('titleId')];

                return posA - posB;
            });

            if (updateWorkOrder) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    workOrder: wo,
                    workOrderTitles: woe
                });
            } else {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    workOrderTitles: woe
                });
            }
            if (titlesToAdd !== undefined && titlesToAdd !== null) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ADD_TITLES,
                    titlesToAdd: titlesToAdd
                });
            }
        }).catch( err => {
            switch (err.status) {
            case 400:
            case 404:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR
                });
                RouterActions.notFound();
                break;
            }
            throw err;
        });
    }

    removeTitle(index) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REMOVE_TITLE,
            index: index
        });
    }

    reorder(a, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.REORDER,
            from: a,
            to: to
        });
    }

    _save(workOrder, workOrderTitles, recipientUserId) {
        let workOrderData = workOrder.delete('mpmNumbers').toJS();
        workOrderData.recipientUserId = recipientUserId;
        let titleIds = [];
        workOrderData.workOrderTitles = workOrderTitles.toJS().map((t, i) => {
            titleIds.push(t.titleId);
            return {
                titleId: t.titleId,
                workOrderId: t.workOrderId,
                displayOrder: i
            };
        });

        if (workOrderData.dueDate) {
            workOrderData.dueDate = Moment(workOrderData.dueDate);
            /* istanbul ignore else */
            if (workOrderData.dueDate.isValid()) {
                workOrderData.dueDate = workOrderData.dueDate.format('YYYY-MM-DDT00:00:00.000'+configtz);
            }
        }

        let method = Request.post;
        let uri = 'work-order';
        let id = workOrderData.id;
        let data = workOrderData;

        if (id !== undefined) {
            method = Request.put;
            uri = `work-order/${id}`;
        }
        workOrderData.needsArtWork = workOrderData.needsArtWork || false;

        return method(uri).send(data).then(orderRes => {
            let postItems = Promise.resolve();
            if (id === undefined) {
                id = orderRes.body.id;
            }
            postItems = Request.post(`work-order/${id}/item`).send(titleIds);

            return Promise.all([orderRes, postItems]);
        }).spread((orderRes/*, postItems*/) => {
            return orderRes;
        });
    }

    save(workOrder, workOrderTitles, recipientUserId, originalWorkOrder, originalWorkOrderTitles) {
        if (workOrder.equals(originalWorkOrder) && workOrderTitles === originalWorkOrderTitles) {
            return;
        }
        Dispatcher.dispatch({
            actionType: CONSTANTS.SAVE.START
        });
        let id = workOrder.get('id');
        this._save(workOrder, workOrderTitles, recipientUserId)
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.SUCCESS,
                    workOrder: Immutable.fromJS(res.body)
                });
                if (!id) {
                    RouterActions.redirect(`/packages/${res.body.id}`);
                }
                NotificationActions.showAlertSuccess('work-orders.save.success');
                return;
            })
            .catch(/* istanbul ignore next */err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.ERROR
                });
                NotificationActions.showAlertDanger('work-orders.save.error');
                throw err;
            });
        return;
    }

    saveAndDownload(workOrder, workOrderTitles, recipientUserId) {
        let workOrderId;
        Dispatcher.dispatch({
            actionType: CONSTANTS.SAVE.START
        });
        this._save(workOrder, workOrderTitles, recipientUserId)
            .then(res => {
                workOrderId = res.body.id;
                RouterActions.redirect(`/packages/${workOrderId}`, true);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.SUCCESS,
                    workOrder: Immutable.fromJS(res.body)
                });
                NotificationActions.showAlertSuccess('work-orders.save_and_download.success');
                return;
            })
            .then(() => {
                this.startDownload(workOrderId);
            })
            .catch(/* istanbul ignore next */err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.ERROR
                });
                NotificationActions.showAlertDanger('work-orders.save.error');
                throw err;
            });
        return;
    }

    saveExportSpecAndDownload(workOrder, workOrderTitles, recipientUserId, exportSpec, originalWorkOrder) {
        if (originalWorkOrder.get('exportSpecification') !== null) {
            DownloadActions.startDownloadExecution(`work-order/web/${workOrder.get('id')}/export-master-meta-data`, {
                'export-type': exportSpec
            });
            return;
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.SAVE.START
        });
        this._save(workOrder, workOrderTitles, recipientUserId)
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.SUCCESS,
                    workOrder: res.body
                });
                DownloadActions.startDownloadExecution(`work-order/web/${workOrder.get('id')}/export-master-meta-data`, {
                    'export-type': exportSpec
                });
                NotificationActions.showAlertSuccess('work-orders.save.success');
            })
            .catch(/* istanbul ignore next */err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.SAVE.ERROR
                });
                NotificationActions.showAlertDanger('work-orders.save.error');
                throw err;
            });
        return;
    }

    startDownload(workOrderId) {
        Request.get(`work-order/web/${workOrderId}/fact-sheet`).query({
            'threaded': true
        }).then(res => {
            Dispatcher.dispatch({
                actionType: DownloadConstants.EXECUTION_ID.GET.SUCCESS,
                downloadExecutionId: res.body.downloadExecutionId
            });
            RouterActions.redirect(`packages/${workOrderId}/download`);

            DownloadActions.watchDownloadExecution(res.body.downloadExecutionId)
                .subscribe(
                    action => Dispatcher.dispatch(action),
                    null,
                    () => void 0
                );

            return;
        }).catch(/* istanbul ignore next */err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.DOWNLOAD.ERROR
            });
            NotificationActions.showAlertDanger('work-orders.download.error');
            throw err;
        });
    }

    statusChange(workOrder, newStatus, closeFunction) {
        let workOrderData = workOrder.toJS();
        workOrderData.status = newStatus;
        Request.put(`work-order/${workOrderData.id}`).send(workOrderData)
            .then(() => {
                NotificationActions.showAlertSuccess('work-orders.status.change.modal.success');
                closeFunction(true);
            })
            .catch(() => {
                NotificationActions.showAlertDanger('work-orders.status.change.modal.error');
                closeFunction();
            });
    }

    updateAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr: attr,
            value: value
        });
    }

    viewDigitalSalesDeck(guid) {
        window.open(`/dsd/${guid}`, '_blank');
    }
}

let actions = new WorkOrderActions();

export {
    actions as WorkOrderActions,
    CONSTANTS as WorkOrderConstants
};
