/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PageLayoutConstants} from './page-layout-actions';
import {Dispatcher} from '../../flux-helpers';

class PageLayoutStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            searchIcon: {
                hidden: true
            },
            searchBar: {
                fixed: false,
                assetFilter: 'all'
            }
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case PageLayoutConstants.SEARCHICON.ACTIONS.SHOW:
            state = state.setIn(['searchIcon', 'hidden'], false);
            break;
        case PageLayoutConstants.SEARCHICON.ACTIONS.HIDE:
            state = state.setIn(['searchIcon', 'hidden'], true);
            break;

        case PageLayoutConstants.SEARCHBAR.ACTIONS.FIX:
            state = state.setIn(['searchBar', 'fixed'], true);
            break;
        case PageLayoutConstants.SEARCHBAR.ACTIONS.UNFIX:
            state = state.setIn(['searchBar', 'fixed'], false);
            break;
        case PageLayoutConstants.SEARCHBAR.ACTIONS.CHANGE:
            state = state.setIn(['searchBar', 'assetFilter'], action.assetFilter);
            break;
        }
        return state;
    }
}

let store = new PageLayoutStore(Dispatcher);
export default store;
