/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {Actions, Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    LOADED: 'preauthenticated.loaded',
};

class PreauthenticatedActions extends Actions {
    load(token) {
        return Request.get(`web/share-link/pre-authenticated/${token}`).then(shared => {
            if (shared.body.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO) {
                // for consistency
                shared.body.sharedVideo.streams = [shared.body.sharedVideoStream];
                shared.body.sharedVideo.thumbnails = [];
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.LOADED,
                shared: Immutable.fromJS(shared.body)
            });
            return;
        }).catch(err => {
            RouterActions.redirect('/login');
            setTimeout(() => NotificationActions.showAlertDanger('preauthenticated.please.login'));
            throw err;
        });
    }
}

let actions = new PreauthenticatedActions();

export {
    actions as PreauthenticatedActions,
    CONSTANTS as PreauthenticatedConstants,
};
