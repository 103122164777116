/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MerchandiseConstants} from './merchandise-actions';
import {Dispatcher} from '../../flux-helpers';

class MerchandiseStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            currentMerchandise: undefined,
            merchandise: Immutable.Map(),
            showCaptions: false
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case MerchandiseConstants.CLEAR:
            state = this.getInitialState();
            break;

        case MerchandiseConstants.CAPTIONS.TOGGLE:
            state = state.set('showCaptions', !state.get('showCaptions'));
            break;

        case MerchandiseConstants.GALLERY.CLOSE:
            state = state.remove('currentMerchandise');
            break;

        case MerchandiseConstants.GALLERY.OPEN:
            state = state.set('currentMerchandise', action.merchandise);
            break;

        case MerchandiseConstants.MERCHANDISE.GET.SUCCESS:
            state = state.setIn(['merchandise', action.merchandise.get('id')], action.merchandise);
            break;
        }

        return state;
    }
}

export default new MerchandiseStore(Dispatcher);
