/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {WorkOrderConstants} from './work-order-actions';
import {Dispatcher} from '../flux-helpers';

class WorkOrderStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.Map({
            isWorkOrderLoading: false,
            titlesToAdd: Immutable.List(),
            workOrder: Immutable.Map({
                fallbackEnglish: true,
                status: WorkOrderConstants.STATUS_BY_NAME.ACTIVE
            }),
            workOrderTitles: Immutable.List()
        });

        state = state.merge({
            originalWorkOrder: state.get('workOrder'),
            originalWorkOrderTitles: state.get('workOrderTitles')
        });

        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case WorkOrderConstants.ADD_TITLES:
            action.titlesToAdd.forEach(title => {
                state = state.setIn(['workOrderTitles'], state.get('workOrderTitles').push(title));
            });
            break;

        case WorkOrderConstants.CLEAR:
            state = this.getInitialState();
            break;

        case WorkOrderConstants.GET.ERROR:
        case WorkOrderConstants.SAVE.ERROR:
            state = state.set('isWorkOrderLoading', false);
            break;

        case WorkOrderConstants.GET.START:
        case WorkOrderConstants.SAVE.START:
            state = state.set('isWorkOrderLoading', true);
            break;

        case WorkOrderConstants.GET.SUCCESS:
            state = state.merge({
                isWorkOrderLoading: false,
                originalWorkOrderTitles: action.workOrderTitles,
                workOrderTitles: action.workOrderTitles
            });
            if (action.workOrder) {
                state = state.merge({
                    originalWorkOrder: action.workOrder,
                    workOrder: action.workOrder,
                });
            }
            break;

        case WorkOrderConstants.REORDER:
            state = state.updateIn(['workOrderTitles'], wot => {
                let from = wot.get(action.from);
                return wot.remove(action.from)
                    .insert(action.to, from);
            });
            break;

        case WorkOrderConstants.REMOVE_TITLE:
            state = state.updateIn(['workOrderTitles'], wot => {
                return wot.remove(action.index);
            });
            break;

        case WorkOrderConstants.SAVE.SUCCESS:
            state = state.merge({
                isWorkOrderLoading: false,
                originalWorkOrder: action.workOrder,
                originalWorkOrderTitles: state.get('workOrderTitles'),
                workOrder: action.workOrder
            });
            break;

        case WorkOrderConstants.UPDATE:
            state = state.setIn(['workOrder', ...action.attr.split('.')], action.value);
            break;

        }

        return state;
    }
}

export default new WorkOrderStore(Dispatcher);
