/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';
import SessionStore from '../../session/session-store';

const CONSTANTS = {
    CONTACTS: {
        GET: {
            START: 'contact-select.contacts.get.start',
            SUCCESS: 'contact-select.contacts.get.success',
            ERROR: 'contact-select.contacts.get.error'
        }
    }
};

class ContactSelectActions {

    /**
     * Get client-rep-group users
     * @param  {int} offset
     * @param  {int} size   [max size result]
     * @return {action} [description]
     */
    get(offset, size) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CONTACTS.GET.START,
        });
        let s = size || 9999;
        Request.get('security/client-rep-group/browse-rep').query({
            offset: offset,
            size: s
        }).set(
            'WBTV-Partner-Id', SessionStore.getPartner().id
        ).set(
            'WBTV-Partner-Key', SessionStore.getPartner().key
        ).then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CONTACTS.GET.SUCCESS,
                options: Immutable.fromJS(response.body.results)
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.CONTACTS.GET.ERROR,
            });
            throw err;
        });
    }

}

let actions = new ContactSelectActions(Dispatcher);

export {
    actions as ContactSelectActions,
    CONSTANTS as ContactSelectConstants
};
