/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {PartnerConstants} from './partner-actions';
import {Dispatcher} from '../flux-helpers';

class PartnerStore extends ReduceStore {
    getPartner(partnerId) {
        return this.getState().get('partners').find(p => parseInt(p.get('id')) === partnerId, null, Immutable.Map());
    }

    getInitialState() {
        let state = Immutable.Map({
            offset: 0,
            partners: Immutable.List(),
            size: 0,
            total: 0,
        });

        return state;
    }

    reduce(state, action) {
        switch (action.actionType) {

        case PartnerConstants.LOAD:
            state = state.merge({
                offset: action.offset,
                partners: action.partners,
                size: action.size,
                total: action.total,
            });
            break;

        }
        return state;
    }
}

let store = new PartnerStore(Dispatcher);

export default store;
