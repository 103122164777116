/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import Request from '../../request';

const CONSTANTS = {
    SUPPORT: {
        CLEAR: 'support_actions.clear',
        SHOW_SUPPORT_MODAL: 'support_actions.show.support.modal',
        REQUEST_SUPPORT: {
            SUCCESS: 'support_actions.request_support.success',
        },
        UPDATE_ATTR: 'support_actions.update.attr'
    }
};

class SupportActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUPPORT.CLEAR
        });
    }

    requestSupport(requestInfo) {
        Request.post('support/ticket').send(requestInfo.toJS()).then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SUPPORT.REQUEST_SUPPORT.SUCCESS,
                response: Immutable.fromJS(res.body),
                requestInfo: requestInfo,
            });
            NotificationActions.showAlertSuccess('account.support.request.success');
            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('account.support.request.error');
            throw err;
        });
    }

    showSupportModal(flag) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUPPORT.SHOW_SUPPORT_MODAL,
            flag: flag
        });
    }

    updateAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUPPORT.UPDATE_ATTR,
            attr: attr,
            value: value
        });
    }
}

const actions = new SupportActions();

export {
    actions as SupportActions,
    CONSTANTS as SupportConstants,
};
