/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'help.get.error',
        START: 'help.get.start',
        SUCCESS: 'help.get.success'
    }
};

class HelpActions {
    get() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        // Fetch publish lists by name to get ids
        Request.get('web/publish-list').query({name: 'WBTVD Help Page', offset: 0}).then(plRes => {
            let list = plRes.body.results[0];
            // No list means user doesn't have permissions to see this page.
            if (!list) {
                RouterActions.forbidden();
                return;
            }
            return [
                list,
                Request.get(`web/publish-list/${list.id}/my-items`)
            ];
        }).spread((list, itemsRes) => {
            list.items = itemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);

            // Add the items to the publishing list.
            return [
                list,
                Promise.all(
                    list.items.map(
                        item => Request.get(`asset/video/${item.assetId}`).then(res => res).catch(err => {
                            // Catch errors if asset is not found.
                            NotificationActions.showAlertWarning(
                                'help.errors.video-not-found', false, item.description.replace(/<\/?div>/, '')
                            );
                            console.error(err);
                            return {body: {}};
                        })
                    )
                ),
                Promise.all(
                    list.items.map(
                        item => Request.get('asset/video/thumbnailURL').query({'video-id': item.assetId}).then(res => res).catch(err => {
                            // Catch errors if no thumbnail.
                            console.error(err);
                            return {
                                body: [{
                                    thumbnailList: []
                                }]
                            };
                        })
                    )
                )
            ];
        }).spread((list, itemsDetailRes, itemsThumbnailsRes) => {
            list.items = list.items.map((item, index) => {
                item.video = itemsDetailRes[index].body;
                item.video.thumbnails = itemsThumbnailsRes[index].body[0].thumbnailList;
                return item;
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                helpPublishingList: Immutable.fromJS(list)
            });

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.ERROR
            });

            NotificationActions.showAlertDanger('help.errors.loading');

            throw err;
        });
        return;
    }
}

let actions = new HelpActions();

export {
    actions as HelpActions,
    CONSTANTS as HelpConstants,
};
