/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ShareAssetConstants} from './share-asset-actions';
import Validations from '../common/validations/validations';
import {Dispatcher} from '../flux-helpers';

const ShareAssetValidations = {
    email: {
        label: 'title.modal.share.validations.email',
        validations: [Validations.required, Validations.email]
    },
    daysActive: {
        label: 'title.modal.share.validations.days-active',
        validations: [Validations.required, Validations.custom(
            /* istanbul ignore next */()=>{return 'Days Active must be 1 to 14';},
            /* istanbul ignore next */(value)=>{
                return (value >= 1 && value <= 14);
            })]
    },
    views: {
        label: 'title.modal.share.validations.allowed-views',
        validations: [Validations.required, Validations.custom(
            /* istanbul ignore next */()=>{return 'Allowed Views must be 1 to 500';},
            /* istanbul ignore next */(value)=>{
                return (value >= 1 && value <= 500);
            })]
    },
};

class ShareAssetStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            preAuthenticatedShare: Immutable.List(),
            share: Immutable.Map({
                selectedClient: undefined,
                email: '',
                daysActive: 3,
                preAuthenticated: false,
                views: 10
            }),
        });
    }

    getValidations() {
        if (!this.getState().getIn(['share', 'preAuthenticated'])) {
            // Ensure a client has been chosen
            if (this.getState().getIn(['share', 'selectedClient', 'id'])) {
                return [];
            }
            return ['Send to email invalid'];

        }
        return Validations.validate(this.getState().get('share'), ShareAssetValidations);

    }

    reduce(state, action) {
        switch (action.actionType) {
        case ShareAssetConstants.SHARE.CLEAR:
            state = this.getInitialState();
            break;

        case ShareAssetConstants.SHARE.GENERATE_PREAUTH_LINK.SUCCESS:
            state = state.set('preAuthenticatedShare', action.details);
            break;

        case ShareAssetConstants.SHARE.UPDATE_SHARE_PROFILE:
            state = state.setIn(['share', action.attr], action.value);
            break;
        }

        return state;
    }
}

const store = new ShareAssetStore(Dispatcher);
export default store;
export {ShareAssetValidations};
