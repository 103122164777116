/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TitleLanguageAvailabilityConstants} from './title-language-availability-actions';
import {Dispatcher} from '../flux-helpers';

class TitlelanguageAvailabilityStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            languages: Immutable.List(),
            titleLanguageAvailability: Immutable.Map({
                results: Immutable.List()
            })
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case TitleLanguageAvailabilityConstants.LANGUAGES.GET.SUCCESS:
            return state.set('languages', action.languages);
        case TitleLanguageAvailabilityConstants.TITLE_LANGUAGE_AVAILABILITY.GET.SUCCESS:
            return state.set('titleLanguageAvailability', action.titleLanguageAvailability);
        default:
            return state;
        }
    }
}

export default new TitlelanguageAvailabilityStore(Dispatcher);
