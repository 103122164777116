/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Redirect} from 'react-router-dom';
import url from 'url';

import Request from '../request';
import {SessionActions} from '../session/session-actions';
import SessionStore from '../session/session-store';

const CheckPartners = (Component) =>{
    return class CheckPartnersImpl extends React.Component {
        render() {
            return (
                <Component {...this.props}/>
            );
        }
    };
};

// eslint-disable-next-line default-param-last
export default (Component, privateRoute = false, permission, partners) => {
    return class RouterHelper extends React.Component {
        static get propTypes() {
            return {
                location: PropTypes.object.isRequired
            };
        }

        checkForward(forward) {
            // true to parse the query string object too.
            const forwardUrlObject = url.parse(forward, true);
            Request.get('system/valid-forward-domain').query({
                domain: `${forwardUrlObject.protocol}//${forwardUrlObject.host}`
            }).then(() => {
                // As per WPB-5389, just paste the auth token to the end of the
                // given URL.
                window.location.replace(forwardUrlObject.href + SessionStore.getAuthorization());
                return;
            }).catch(err => {
                // This means the server doesn't recognize the forward URL as
                // a valid one. Log the error and do nothing.
                console.error(err);
                return;
            });
        }

        render() {
            const {sso, partnerId, partnerKey} = QueryString.parse(this.props.location.search);
            if (sso) {
                const partner = {id: partnerId, key: partnerKey};
                SessionActions.login(null, null, '/', null, partner, null, sso);
            }

            if (privateRoute) {
                if (!SessionStore.isSessionValid()) {
                    // This is because of having multiple entrypoints. If the user has loaded
                    // the LAScreenings HTML, then he must stay in it.
                    // If we redirect him to /login, that will create an infinite loop in
                    // Webpack's dev server.
                    // IMPORTANT: this is only a problem during development.
                    if (this.props.location.pathname === '/lascreenings') {
                        return (
                            <Redirect
                                to={'/lascreenings/login'}
                            />
                        );
                    }

                    let previousURL = encodeURIComponent(`${this.props.location.pathname}${this.props.location.search}`);
                    return (
                        <Redirect
                            to={`/login?next=${previousURL}`}
                        />
                    );
                }
                if (SessionStore.canUser(SessionStore.PERMISSIONS.RESTRICTED.HOMEPAGEONLY) && this.props.location.pathname !== '/') {
                    return (
                        <Redirect
                            to="/"
                        />
                    );
                }
                if (SessionStore.isBaftaUser()) {
                    return (
                        <Redirect
                            to={`/bafta-video-player?anonymous-id=${SessionStore.getAnonymousId()}`}
                        />
                    );
                }
                if (partners !== undefined) {
                    if (partners.indexOf(SessionStore.getState().getIn(['partner', 'id'])) === -1) {
                        return (
                            <Redirect
                                to="/"
                            />
                        );
                    }
                }
            } else if (SessionStore.isSessionValid()) {
                const {forward} = QueryString.parse(this.props.location.search);
                // If the URL contains a forward query param, then parse it and check if it belongs
                // to a valid domain.
                if (forward) {
                    this.checkForward(forward);
                }

                return (
                    <Redirect
                        to="/"
                    />
                );
            }

            if (permission) {
                if (!SessionStore.canUser(permission)) {
                    return (
                        <Redirect
                            to="/access-denied"
                        />
                    );
                }
            }


            return (
                <Component {...this.props}/>
            );
        }
    };
};

export {
    CheckPartners
};
