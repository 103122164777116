/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {VamConstants} from './vam-actions';
import {Dispatcher} from '../../flux-helpers';

class VamStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            vamList: Immutable.List(),
            loading: false
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case VamConstants.GET.ERROR:
            state = state.merge({
                loading: false
            });
            break;

        case VamConstants.GET.START:
            state = state.merge({
                loading: true
            });
            break;

        case VamConstants.GET.SUCCESS:
            state = state.merge({
                vamList: action.vamList,
                loading: false
            });
            break;
        }

        return state;
    }
}

export default new VamStore(Dispatcher);
