/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {convertVideoFragmentToClip} from '../common/clips/converters';
import Config from '../config/config';
import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {TitleConstants} from '../titles/title-actions';

const CONSTANTS = {
    ACTION_TYPE: {
        LIVE_ACTION: 1,
        ANIMATION: 2
    },
    CLEAR: 'search_actions.clear',
    DISPLAY_MODES: {
        GRID: 'grid',
        LIST: 'list'
    },
    // Actions for the filters bar.
    FILTERS: {
        TALENT: {
            SUCCESS: 'search_actions.filters.talent.success'
        }
    },
    HUBS_PUBLISHING_LISTS: {
        animation: {id: 40, name: 'Animation Hub'},
        documentary: {id: 1642, name: 'HBO Documentary Hub'},
        entertainment: {name: 'Entertainment Hub'},
        factual: {name: 'Factual Hub'},
        film: {id: 120, name: 'Film Hub'},
        'limited-series': {id: 1643, name: 'HBO Limited Series Hub'},
        movies: {id: 1644, name: 'HBO Movies Hub'},
        reality: {name: 'Reality Hub'},
        scripted: {name: 'Scripted Hub'},
        tv: {id: 80, name: 'TV Hub'},
    },
    MADE_FOR: {
        TV: 1,
        FILM: 2,
        HOME_VIDEO: 3,
        WEB: 4
    },
    PUBLISHING_LIST: {
        GET: {
            SUCCESS: 'search_actions.publishing_list.get.success'
        }
    },
    SUGGESTIONS: {
        CLEAR: 'search_actions.suggestions.clear',
        GET: {
            SUCCESS: 'search_actions.suggestions.get.success'
        }
    },
    // Actions for the search results sections.
    SEARCH: {
        CLIPS: {
            ERROR: 'search_actions.search.clips.error',
            IMAGE_ID_ATTR: 'videoId',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign({}, baseQuery, {
                    'is-clippable': true,
                    'search-term': criteria.q,
                    talent: criteria.talent,
                });

                // TODO: fill me when server will be ready
                // TODO: made-for
                // TODO: title-catalog
                // TODO: category

                return q;
            },
            SUCCESS: 'search_actions.search.clips.success',
            RESULTS: function(results) {
                return results.map(convertVideoFragmentToClip);
            },
            URL: 'asset/video-search',
        },
        DOCUMENTS: {
            ERROR: 'search_actions.search.documents.error',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign({}, baseQuery, {'asset-name': criteria.q});

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'assetName';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                default:
                    // This removes invalid values like "relevance".
                    delete q['sort-field-name'];
                }

                q['sort-direction'] = criteria['sort-direction'];

                return q;
            },
            URL: 'asset/web/document',
            SUCCESS: 'search_actions.search.documents.success'
        },
        EPISODES: {
            ERROR: 'search_actions.search.episodes.error',
            IMAGE_ID_ATTR: 'defaultImagePortraitId',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign(
                    {},
                    baseQuery,
                    {
                        talent: criteria.talent,
                        title: criteria.q
                    }
                );

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'name';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                case 'releaseDate':
                    q['sort-field-name'] = 'titleReleaseDate';
                    break;
                default:
                    // Set "releaseDate" and descendent as default sort.
                    q['sort-field-name'] = 'titleReleaseDate';
                }

                if (criteria['sort-direction'] !== undefined) {
                    q['sort-direction'] = criteria['sort-direction'];
                } else {
                    q['sort-direction'] = 'desc';
                }

                return q;
            },
            URL: 'title/web/episode',
            SUCCESS: 'search_actions.search.episodes.success'
        },
        IMAGES: {
            ERROR: 'search_actions.search.images.error',
            IMAGE_ID_ATTR: 'id',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign({}, baseQuery, {'asset-name': criteria.q});

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'assetName';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                default:
                    // This removes invalid values like "relevance".
                    delete q['sort-field-name'];
                }

                q['sort-direction'] = criteria['sort-direction'];

                return q;
            },
            URL: 'asset/web/image',
            SUCCESS: 'search_actions.search.images.success'
        },
        START: 'search_actions.search.start',
        TALENT: {
            ERROR: 'search_actions.search.talent.error',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign({}, baseQuery, {'aka-name': criteria.q});

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'lastName';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                default:
                    // This removes invalid values like "relevance".
                    delete q['sort-field-name'];
                }

                q['sort-direction'] = criteria['sort-direction'];

                return q;
            },
            URL: 'talent/web/talent',
            SUCCESS: 'search_actions.search.talent.success',
        },
        THUMBNAILS: {
            SUCCESS: 'search_actions.search.thumbnails.success'
        },
        TITLES: {
            ERROR: 'search_actions.search.titles.error',
            IMAGE_ID_ATTR: 'defaultImagePortraitId',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign(
                    {},
                    baseQuery,
                    {
                        size: criteria.size,
                        talent: criteria.talent,
                        title: criteria.q
                    }
                );

                q['start-run-time'] = criteria['start-run-time'];
                q['end-run-time'] = criteria['end-run-time'];

                Object.keys(q).forEach(k => {
                    if (q[k] === undefined) {
                        delete q[k];
                    }
                });

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'name';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                case 'releaseDate':
                    q['sort-field-name'] = 'titleReleaseDate';
                    break;
                default:
                    // Set "releaseDate" and descendent as default sort.
                    q['sort-field-name'] = 'titleReleaseDate';
                }

                if (criteria['sort-direction'] !== undefined) {
                    q['sort-direction'] = criteria['sort-direction'];
                } else {
                    q['sort-direction'] = 'desc';
                }

                return q;
            },
            URL: 'title/web/title',
            SUCCESS: 'search_actions.search.titles.success',
        },
        VIDEOS: {
            ERROR: 'search_actions.search.videos.error',
            IMAGE_ID_ATTR: 'id',
            QUERY: function(baseQuery, criteria) {
                let q = Object.assign({}, baseQuery, {'asset-name': criteria.q});

                switch (criteria['sort-field-name']) {
                case 'alphabetical':
                    q['sort-field-name'] = 'assetName';
                    break;
                case 'createdDate':
                    q['sort-field-name'] = 'createdDate';
                    break;
                default:
                    // This removes invalid values like "relevance".
                    delete q['sort-field-name'];
                }

                q['sort-direction'] = criteria['sort-direction'];
                q.barcode = criteria.barcode;

                return q;
            },
            URL: 'asset/web/video',
            SUCCESS: 'search_actions.search.videos.success'
        }
    },
    SET: {
        DISPLAY_MODE: 'search_actions.set.display_mode',
        DOCUMENT: {
            // Set an image for a document (used when getting the default image).
            IMAGE_ID: 'search_actions.set.document.image_id'
        },
        TALENT: {
            AKA: 'search_actions.set.talent.aka',
            // Set an image for a talent (used when getting the default image).
            IMAGE_ID: 'search_actions.set.talent.image_id',
        },
        TITLE: {
            // Extra title details, required for search-list columns
            DETAILS: 'search_actions.set.title.details',
        },
    },
    STANDARD: {
        HD: {
            '1080I': 1500041,
            '1080P': 1500040
        },
        SD: {
            'NTSC': 1500042,
            'PAL': 1500043
        }
    },
    TIME_FRAME: {
        pastweek: {
            api: 'PAST_WEEK',
        },
        pastmonth: {
            api: 'PAST_MONTH',
        },
        pastyear: {
            api: 'PAST_YEAR',
        },
        today: {
            api: 'TODAY',
        }
    }
};

class SearchActions {
    constructor() {
        // Map to store all requests.
        this.requests = {};
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearSuggestions() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SUGGESTIONS.CLEAR
        });

        return;
    }

    formatRuntime(value) {
        /* the API expects this value as minutes only so this function splits
         hours from minutes in order to return the value as minutes only */
        let runtime = Moment.duration(0, 'minutes').asMinutes();
        let valueArr = value.split(/[ :]/);
        valueArr.forEach(t => {
            if (t.match(/h/)) {
                let hours = t.split('h');
                runtime += Moment.duration(parseInt(hours[0], 10), 'hours').asMinutes();
            } else {
                let minutes = t.split('m');
                runtime += Moment.duration(parseInt(minutes[0], 10), 'minutes').asMinutes();
            }
        });
        return runtime;
    }

    getDocumentDetails(documents) {
        if (!documents || !documents.length) {return;}

        return Promise.all(documents.map(d => {
            // Handle documents that don't have browseTitleList entries
            if (!d.browseTitleList.length) {return;}

            let titleId = d.browseTitleList[0].id;

            return Request.get(`title/${titleId}`)
                .then(tRes => {
                    if (tRes.body.defaultImagePortraitId) {
                        let defaultImagePortraitId = tRes.body.defaultImagePortraitId.toString();

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SEARCH.THUMBNAILS.SUCCESS,
                            thumbnails: Immutable.fromJS({
                                [defaultImagePortraitId]: {
                                    previewUrl: tRes.body.defaultImagePortraitPreviewUrl,
                                    sourceUrl: tRes.body.defaultImagePortraitSourceUrl,
                                    thumbnailUrl: tRes.body.defaultImagePortraitThumbnailUrl
                                }
                            })
                        });

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SET.DOCUMENT.IMAGE_ID,
                            documentId: d.id,
                            imageId: tRes.body.defaultImagePortraitId
                        });
                    }
                });
        }));
    }

    getHubPublishList(hub) {
        if (
            hub === 'search' ||
            !CONSTANTS.HUBS_PUBLISHING_LISTS[hub].id
        ) {
            return;
        }
        let list;
        Request.get(`web/publish-list/${CONSTANTS.HUBS_PUBLISHING_LISTS[hub].id}`).then(plRes => {
            list = plRes.body;
            if (!list) {
                throw new Error('No publishing list found.');
            }

            return Request.get(`web/publish-list/${list.id}/my-items`);
        }).then(itemsRes => {
            list.items = itemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);
            if (!list.items) {
                throw new Error('No items for the publishing list.');
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.PUBLISHING_LIST.GET.SUCCESS,
                publishingList: Immutable.fromJS(list)
            });

            // Now we have to get the images for each item.
            let getUrl = {
                '1-null': (id) => `title/${id}`,
                '2-image': (id) => `asset/image/url?image-id=${id}`,
                '2-video': (id) => `asset/video/thumbnailURL?video-id=${id}`
            };

            // Now iterate all items and get all needed images.
            // Save the requests in a promises object using the key: targetType-assetId.
            let requests = {};
            list.items.filter(i => [1, 2].indexOf(i.targetType) !== -1).forEach(i => {
                let url = getUrl[`${i.targetType}-${i.displayAssetTypeName}`.toLowerCase()](i.assetId);
                requests[`${i.targetType}-${i.assetId}`] = Request.get(url);
                return;
            });

            return Promise.props(requests);
        }).then(thumbnailsRes => {
            list.items = list.items.map(i => {
                let itemDetail = thumbnailsRes[`${i.targetType}-${i.assetId}`];
                if (itemDetail) {
                    switch (i.targetType) {
                    // Title.
                    case 1:
                        i.thumbnails = [{
                            thumbnailUrl: itemDetail.body.defaultImageThumbnailUrl
                        }];
                        break;
                    // Asset.
                    case 2:
                        if (i.displayAssetTypeName === 'Image') {
                            i.thumbnails = itemDetail.body;
                        }
                        if (i.displayAssetTypeName === 'Video') {
                            i.thumbnails = itemDetail.body[0].thumbnailList;
                        }
                        break;
                    }

                }

                return i;
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.PUBLISHING_LIST.GET.SUCCESS,
                publishingList: Immutable.fromJS(list)
            });

            return;
        }).catch(err => {
            throw err;
        });

        return;
    }

    getImages(ids, url) {
        if (!ids.length) {return;}

        ids = ids.filter(id => !!id);

        switch (url) {
        case 'asset/video-search':
        case 'asset/web/video':
            return Request.get('asset/video/thumbnailURL').query({'video-id': ids}).then(resThumbnails => {
                let thumbnails = resThumbnails.body.reduce((res, nextRes) => {
                    let thumbnail = {
                        videoId: nextRes.videoId,
                        // To remove loading indicator.
                        thumbnailUrl: null
                    };

                    if (nextRes.thumbnailList.length) {
                        thumbnail = nextRes.thumbnailList.reduce((a, b) => {
                            let aValue = Math.abs(a.width - 250);
                            let bValue = Math.abs(b.width - 250);

                            if (aValue < bValue) {
                                return a;
                            }

                            return b;
                        });
                    }

                    res[nextRes.videoId.toString()] = thumbnail;
                    return res;
                }, {});

                Dispatcher.dispatch({
                    actionType: CONSTANTS.SEARCH.THUMBNAILS.SUCCESS,
                    thumbnails: Immutable.fromJS(thumbnails)
                });

                return;
            });

        default:
            return Request.get('asset/image/url').query({'image-id': ids}).then(resThumbnails => {
                let thumbnails = resThumbnails.body.reduce((res, nextRes) => {
                    res[nextRes.imageId.toString()] = nextRes;
                    return res;
                }, {});

                Dispatcher.dispatch({
                    actionType: CONSTANTS.SEARCH.THUMBNAILS.SUCCESS,
                    thumbnails: Immutable.fromJS(thumbnails)
                });

                return;
            });
        }
    }

    getSuggestions(term) {
        Request.get('web/auto-suggest').query({'search-term': term}).then(autoSuggestRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.SUGGESTIONS.GET.SUCCESS,
                searchSuggestions: Immutable.fromJS(autoSuggestRes.body),
                query: term
            });
        });
    }

    // Get Talent by id to get the default images.
    getTalent(talent) {
        return Promise.all(
            talent.map(t => {
                return Request.get(`talent/${t.talentId}`).then(tRes => {
                    if (tRes.body.defaultImagePortraitId) {
                        let defaultImagePortraitId = tRes.body.defaultImagePortraitId.toString();

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SEARCH.THUMBNAILS.SUCCESS,
                            thumbnails: Immutable.fromJS({
                                [defaultImagePortraitId]: {
                                    previewUrl: tRes.body.defaultImagePortraitPreviewUrl,
                                    sourceUrl: tRes.body.defaultImagePortraitSourceUrl,
                                    thumbnailUrl: tRes.body.defaultImagePortraitThumbnailUrl
                                }
                            })
                        });

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SET.TALENT.IMAGE_ID,
                            talentId: tRes.body.id,
                            imageId: tRes.body.defaultImagePortraitId
                        });
                    }
                });
            })
        );
    }

    /**
    * Load additional details for each talent for search-list
    * @param  {array} list of talent ID values
    * @return {promise}
    */
    getTalentDetails(ids) {
        if (!ids || !ids.length) {return;}

        return Promise.all([
            ids.map(id => {
                // Credited As list (akas)
                Request.get(`talent/${id}/aka`).then( resTalent => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SET.TALENT.AKA,
                        talentId: id,
                        aka: Immutable.fromJS(resTalent.body),
                    });
                });
            })
        ]);
    }

    getTalentFilter(term, offset, size) {
        offset = offset || 0;
        size = size || 20;

        Request.get('talent/web/talent').query({'aka-name': term, offset, size}).then(res => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FILTERS.TALENT.SUCCESS,
                talent: Immutable.fromJS(res.body.results)
            });
            return;
        });
        return;
    }

    /**
    * Load additional details for each title so search-list can
    * display required column values.
    * Dispatch an action with the default image.
    * @param  {array} list of title ID values
    * @return {promise}
    */
    getTitleDetails(ids) {
        if (!ids || !ids.length) {return;}

        return Promise.all([
            Request.get('lookup/genre?size=99999'),
            Request.get('lookup/language?size=99999')
        ]).spread((resLookupGenre, resLookupLanguage) => {
            const lookupGenres = Immutable.fromJS(resLookupGenre.body.results);
            const lookupLanguages = Immutable.fromJS(resLookupLanguage.body.results);

            return Promise.all([
                ids.map(id => {
                    return Promise.all([
                        Request.get(`title/${id}`),
                        Request.get(`title/${id}/genre`),
                        Request.get(`title/${id}/language`),
                        Request.get(`title/${id}/related-title`).query({active: true})
                    ]).spread((titleRes, genreRes, languageRes, relatedTitleRes) => {
                        // Lookup genres
                        const genres = genreRes.body.map(r => {
                            let genre = lookupGenres.find(lg => {return lg.get('id') === r.genreId;});
                            if (genre) {return genre.get('name');}
                        });

                        // Lookup languages
                        const languages = languageRes.body.map(r => {
                            let language = lookupLanguages.find(ll => {return ll.get('id') === r.languageId;});
                            if (language) {return language.get('name');}
                        });

                        // Get episode and season count
                        let episodes = [];
                        let seasons = [];

                        // Logic copied from title-actions then refactored to use .EPISODE/.SEASON
                        relatedTitleRes.body.forEach(relatedTitle => {
                            switch (relatedTitle.relationshipType) {
                            case TitleConstants.TITLE_RELATIONSHIP_TYPE.EPISODE:
                                episodes.push(relatedTitle);
                                break;
                            case TitleConstants.TITLE_RELATIONSHIP_TYPE.SEASON:
                                seasons.push(relatedTitle);
                                break;
                            }
                        });

                        if (titleRes.body.defaultImagePortraitId) {
                            Dispatcher.dispatch({
                                actionType: CONSTANTS.SEARCH.THUMBNAILS.SUCCESS,
                                thumbnails: Immutable.fromJS({
                                    [titleRes.body.defaultImagePortraitId.toString()]: {
                                        fullResolutionUrl: titleRes.body.defaultImageHorizontalFullResolutionUrl,
                                        previewUrl: titleRes.body.defaultImagePortraitPreviewUrl,
                                        thumbnailUrl: titleRes.body.defaultImagePortraitThumbnailUrl
                                    }
                                })
                            });
                        }

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.SET.TITLE.DETAILS,
                            id,
                            title: Immutable.fromJS(titleRes.body),
                            genres: Immutable.fromJS(genres),
                            languages: Immutable.fromJS(languages),
                            seasons: Immutable.fromJS(seasons),
                            episodes: Immutable.fromJS(episodes)
                        });
                    });
                })
            ]);
        });
    }

    search(sections, criteria, hub, category, partnerId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SEARCH.START
        });

        if (hub !== 'search') {
            // Set the hub type to true. Use a string because in the url, all query
            // values are strings (this makes all further code just work).
            // Possible values might depend on partners and not only be 'animation', 'film', 'tv'.
            // For HBO hubs are 'tv', 'movies', 'limited-series', 'documentary'
            criteria[hub] = 'true';
            if (!criteria['sort-field-name'] || criteria['sort-field-name'] === 'relevance') {
                criteria['sort-field-name'] = `${hub}hub`;
            }
        }

        if (!criteria.size) {
            criteria.size = 40;
            if (sections.length > 1) {
                criteria.size = 8;
            }
        }

        // check if start-date-created is actually a timeFrame
        if (Object.keys(CONSTANTS.TIME_FRAME).indexOf(criteria['start-date-created']) !== -1) {
            // is a time frame
            criteria['time-frame']=CONSTANTS.TIME_FRAME[criteria['start-date-created']].api;
            delete criteria['start-date-created'];
        }

        // Create the base query object for all common parameters.
        let baseQuery = [
            'category',
            'character',
            'country',
            'content-super-type',
            'dialogue',
            'end-box-office',
            'end-date-created',
            'end-release-date',
            'genre',
            'genre-operator',
            'language',
            'library',
            'new',
            'offset',
            'returning',
            'scripted',
            'setting',
            'start-date-created',
            'start-release-date',
            'start-box-office',
            'size',
            'sort-field-name',
            'tag',
            'talent',
            'talent-character',
            'theme',
            'time-frame',
            'title-catalog',
            'unscripted'
        ].reduce((r, param) => {
            if ([null, undefined, ''].indexOf(criteria[param]) !== -1) {
                return r;
            }

            let value = criteria[param];
            if (param.match('end-date')) {
                value = Moment(value).utc().endOf('day').toISOString();
            }

            if (param.match('start-date')) {
                value = Moment(value).utc().startOf('day').toISOString();
            }

            r[param] = value;
            return r;
        }, {});

        // Categories filters.
        if (partnerId === Config.Partners.FORMATS_PORTAL.id) {
            const categories = [TitleConstants.TITLE_TYPES.FORMAT_RIGHTS.id];
            baseQuery.category = categories;

            if (criteria.scripted) {
                baseQuery.scripted = true;
            }

            if (criteria.entertainment) {
                baseQuery.genre = ['Entertainment'];
            }

            if (criteria.factual) {
                baseQuery.genre = ['Entertainment', 'Factual'];
                baseQuery['genre-operator'] = 'OR';
            }

            if (criteria.reality) {
                baseQuery.genre = ['Reality'];
            }
        }

        if (
            partnerId !== Config.Partners.HBO.id &&
            (criteria.tv || criteria.film)
        ) {
            baseQuery['made-for'] = [];

            if (criteria.tv) {
                baseQuery['made-for'].push(
                    CONSTANTS.MADE_FOR.HOME_VIDEO,
                    CONSTANTS.MADE_FOR.TV,
                    CONSTANTS.MADE_FOR.WEB
                );
            }

            if (criteria.film) {
                baseQuery['made-for'].push(
                    CONSTANTS.MADE_FOR.FILM
                );
            }
        }

        // If coming from HBO, set HBO hubs
        if (partnerId === Config.Partners.HBO.id) {
            let categories = [];

            if (!criteria.category) {
                if (criteria.documentary) {
                    categories.push(TitleConstants.TITLE_TYPES.DOCUMENTARIES.id);
                    categories.push(TitleConstants.TITLE_TYPES.DOCUSERIES.id);
                }
                if (criteria['limited-series']) {
                    categories.push(TitleConstants.TITLE_TYPES.LIMITED_SERIES.id);
                    categories.push(TitleConstants.TITLE_TYPES.MINI_SERIES.id);
                }
                if (criteria.movies) {
                    categories.push(TitleConstants.TITLE_TYPES.HBO_FILMS.id);
                    categories.push(TitleConstants.TITLE_TYPES.DOCUMENTARY_FILMS.id);
                    categories.push(TitleConstants.TITLE_TYPES.WB_HBO_MAX_RELEASE.id);
                }
                if (criteria.tv) {
                    categories.push(TitleConstants.TITLE_TYPES.ANIMATED_FEATURES.id);
                    categories.push(TitleConstants.TITLE_TYPES.ANIMATED_SERIES.id);
                    categories.push(TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id);
                    categories.push(TitleConstants.TITLE_TYPES.ANIMATION.id);
                    categories.push(TitleConstants.TITLE_TYPES.CABLE.id);
                    categories.push(TitleConstants.TITLE_TYPES.CARTOONS.id);
                    categories.push(TitleConstants.TITLE_TYPES.COMEDY_SPECIALS.id);
                    categories.push(TitleConstants.TITLE_TYPES.DIRECT_TO_VIDEO.id);
                    categories.push(TitleConstants.TITLE_TYPES.EVENT.id);
                    categories.push(TitleConstants.TITLE_TYPES.FORMAT_RIGHTS.id);
                    categories.push(TitleConstants.TITLE_TYPES.GAME_SHOW.id);
                    categories.push(TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id);
                    categories.push(TitleConstants.TITLE_TYPES.MAKING_OF.id);
                    categories.push(TitleConstants.TITLE_TYPES.MUSIC_SPECIALS.id);
                    categories.push(TitleConstants.TITLE_TYPES.NETWORK.id);
                    categories.push(TitleConstants.TITLE_TYPES.OTHER_PRODUCTS.id);
                    categories.push(TitleConstants.TITLE_TYPES.PAY_TV.id);
                    categories.push(TitleConstants.TITLE_TYPES.PROGRAMMING_PACKAGE.id);
                    categories.push(TitleConstants.TITLE_TYPES.REALITY.id);
                    categories.push(TitleConstants.TITLE_TYPES.SEGMENT.id);
                    categories.push(TitleConstants.TITLE_TYPES.SERIES_HALF_HOUR.id);
                    categories.push(TitleConstants.TITLE_TYPES.SERIES_ONE_HOUR.id);
                    categories.push(TitleConstants.TITLE_TYPES.SHORT_PROGRAMS.id);
                    categories.push(TitleConstants.TITLE_TYPES.SPECIALS.id);
                    categories.push(TitleConstants.TITLE_TYPES.SPORTS.id);
                    categories.push(TitleConstants.TITLE_TYPES.TALK_SHOW.id);
                    categories.push(TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id);
                }
            } else {
                categories.push(criteria.category);
            }

            baseQuery.category = categories;
        }

        // HBO Max
        if (partnerId === Config.Partners.HBO_MAX_PORTAL.id) {

            if (criteria.tv) {
                let categories = [];
                categories.push(TitleConstants.TITLE_TYPES.ANIMATED_FEATURES.id);
                categories.push(TitleConstants.TITLE_TYPES.ANIMATED_SERIES.id);
                categories.push(TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id);
                categories.push(TitleConstants.TITLE_TYPES.ANIMATION.id);
                categories.push(TitleConstants.TITLE_TYPES.CABLE.id);
                categories.push(TitleConstants.TITLE_TYPES.CARTOONS.id);
                categories.push(TitleConstants.TITLE_TYPES.COMEDY_SPECIALS.id);
                categories.push(TitleConstants.TITLE_TYPES.DIRECT_TO_VIDEO.id);
                categories.push(TitleConstants.TITLE_TYPES.DOCUSERIES_SEASON.id);
                categories.push(TitleConstants.TITLE_TYPES.DOCUSERIES.id);
                categories.push(TitleConstants.TITLE_TYPES.EVENT.id);
                categories.push(TitleConstants.TITLE_TYPES.FORMAT_RIGHTS.id);
                categories.push(TitleConstants.TITLE_TYPES.GAME_SHOW.id);
                categories.push(TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id);
                categories.push(TitleConstants.TITLE_TYPES.MAKING_OF.id);
                categories.push(TitleConstants.TITLE_TYPES.MUSIC_SPECIALS.id);
                categories.push(TitleConstants.TITLE_TYPES.NETWORK.id);
                categories.push(TitleConstants.TITLE_TYPES.OTHER_PRODUCTS.id);
                categories.push(TitleConstants.TITLE_TYPES.PAY_TV.id);
                categories.push(TitleConstants.TITLE_TYPES.PROGRAMMING_PACKAGE.id);
                categories.push(TitleConstants.TITLE_TYPES.REALITY.id);
                categories.push(TitleConstants.TITLE_TYPES.SEGMENT.id);
                categories.push(TitleConstants.TITLE_TYPES.SERIES_HALF_HOUR.id);
                categories.push(TitleConstants.TITLE_TYPES.SERIES_ONE_HOUR.id);
                categories.push(TitleConstants.TITLE_TYPES.SHORT_PROGRAMS.id);
                categories.push(TitleConstants.TITLE_TYPES.SPECIALS.id);
                categories.push(TitleConstants.TITLE_TYPES.SPORTS.id);
                categories.push(TitleConstants.TITLE_TYPES.TALK_SHOW.id);
                categories.push(TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id);

                baseQuery.category = categories;
            }

            if (criteria.documentary) {
                baseQuery.category = [TitleConstants.TITLE_TYPES.DOCUMENTARY_FILMS.id];
            }

            if (criteria['limited-series']) {
                baseQuery.category = [TitleConstants.TITLE_TYPES.LIMITED_SERIES.id];
            }

            if (criteria.kids) {
                baseQuery.genre = ['Family', 'Children/ Kids'];
                baseQuery['genre-operator'] = 'OR';
            }
        }

        let actionTypes = [];
        if (criteria.animation || criteria.animated) {
            actionTypes.push(CONSTANTS.ACTION_TYPE.ANIMATION);
        }
        if (criteria['live-action']) {
            actionTypes.push(CONSTANTS.ACTION_TYPE.LIVE_ACTION);
        }
        // Only add the filter if there's one action type selected. More than one means
        // we should match everything, which is the same as not sending any filters.
        if (actionTypes.length === 1) {
            baseQuery['action-type'] = actionTypes[0];
        }

        if (criteria.dialogue) {
            baseQuery['dialogue-operator'] = 'OR';
        }

        if (criteria.setting) {
            baseQuery['setting-operator'] = 'OR';
        }

        if (criteria.hd || criteria.sd) {
            baseQuery['standard-id'] = [];
            baseQuery['standard-id-operator'] = 'OR';

            if (criteria.hd) {
                baseQuery['standard-id'].push(CONSTANTS.STANDARD.HD['1080I'], CONSTANTS.STANDARD.HD['1080P']);
            }

            if (criteria.sd) {
                baseQuery['standard-id'].push(CONSTANTS.STANDARD.SD.NTSC, CONSTANTS.STANDARD.SD.PAL);
            }
        }

        // If coming from SHOWS in PRESS RELEASES only search for titles, set displayShowSize and filter series
        if (category === 'shows') {
            let categories = Object.keys(TitleConstants.TITLE_TYPES).reduce((arr, t) => {
                if (TitleConstants.TITLE_TYPES[t].categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES) {
                    arr = arr.concat(TitleConstants.TITLE_TYPES[t].id);
                }
                return arr;
            }, []);

            // BRAIN-53 also add "Made For TV Movies - Network"
            categories.push(TitleConstants.TITLE_TYPES.NETWORK.id);

            categories.push(TitleConstants.TITLE_TYPES.ANIMATED_FEATURES.id);
            categories.push(TitleConstants.TITLE_TYPES.ANIMATION.id);
            categories.push(TitleConstants.TITLE_TYPES.COMEDY_SPECIALS.id);
            categories.push(TitleConstants.TITLE_TYPES.SPECIALS.id);

            // BRAIN-1055 to show Theatrical Features and Made for Video Features (categories ID #1 and #3)
            if (partnerId === Config.Partners.PRESS_SITE.id) {
                categories.push(TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id);
                categories.push(TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id);
                // BRAIN-1121 add "Warner Bros: HBO Max Release"
                categories.push(TitleConstants.TITLE_TYPES.WB_HBO_MAX_RELEASE.id);
            }

            // BRAIN-1965 add to Shows: Music Specials, Made-For-Video Features and Animated Specials
            if (partnerId === Config.Partners.CARTOONITO_PRESS.id) {
                categories.push(TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id);
                categories.push(TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id);
                categories.push(TitleConstants.TITLE_TYPES.MUSIC_SPECIALS.id);
            }

            baseQuery.category = categories;
        }

        if (criteria['end-run-time']) {
            criteria['end-run-time'] = this.formatRuntime(criteria['end-run-time']);
        }

        if (criteria['start-run-time']) {
            criteria['start-run-time'] = this.formatRuntime(criteria['start-run-time']);
        }
        // Get search results for each type.

        sections.forEach(s => {
            Request.get(s.URL).query(s.QUERY(baseQuery, criteria)).then(searchRes => {
                const convert = s.RESULTS || ((res) => res);
                let results = convert(searchRes.body.results);
                if (results[0] && Array.isArray(results.browseTitleList)) {
                    results = results.filter(r => !!r.browseTitleList.length);
                }
                Dispatcher.dispatch({
                    actionType: s.SUCCESS,
                    downloadUrlBase: s.URL,
                    downloadUrlQuery: s.QUERY(baseQuery, criteria),
                    results: Immutable.fromJS(results),
                    totalCount: searchRes.body.totalCount
                });

                // If we have title results (tv, film, animation hub, etc) - Load additional title details
                // This method will provide the default images too, as requested by WPB-5306.
                if (s.URL.match('title')) {
                    return this.getTitleDetails(results.map(res => {return res.id;}));
                }

                if (s.URL.match('talent')) {
                    this.getTalentDetails(results.map(res => {return res.talentId;}));
                    return this.getTalent(results);
                }

                if (s.URL.match('document')) {
                    return this.getDocumentDetails(results);
                }

                // Get images for Image and Video results.
                let ids = [];
                if (s.IMAGE_ID_ATTR) {
                    ids = results.map(res => {
                        return res[s.IMAGE_ID_ATTR];
                    }).filter(id => id !== undefined);
                }

                return this.getImages(ids, s.URL);
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: s.ERROR
                });

                throw err;
            });

            return;
        });

        return;
    }

    setDisplayMode(mode) {
        if (!mode) {return;}

        Dispatcher.dispatch({
            actionType: CONSTANTS.SET.DISPLAY_MODE,
            mode: mode
        });
    }

    saveSearchName(searchName, searchUrl, userId) {
        Request.post(`user/${userId}/saved-search`)
            .send({
                searchName: searchName,
                searchUrl: searchUrl
            })
            .then(() => {
                NotificationActions.showAlertSuccess('search.save-search.success');
            })
            .catch(err => {
                NotificationActions.showAlertDanger('search.save-search.error');
                throw err;
            });
    }

    shareSearch(email, searchUrl, userId) {
        Request.post(`user/${userId}/saved-search`)
            .send({
                searchName: `Shared to: ${email}`,
                searchUrl: searchUrl
            })
            .then(response => {

                let savedSearchId = response.body.id;

                Request.post(`user/${userId}/saved-search/${savedSearchId}/share`)
                    .send({
                        emailAddress: email,
                    })
                    .then(() => {
                        NotificationActions.showAlertSuccess('search.share-search.success');
                    })
                    .catch(err => {
                        NotificationActions.showAlertDanger('search.share-search.error');
                        throw err;
                    });

            })
            .catch(err => {
                NotificationActions.showAlertDanger('search.share-search.error');
                throw err;
            });
    }
}

let actions = new SearchActions();

export {
    actions as SearchActions,
    CONSTANTS as SearchConstants
};
