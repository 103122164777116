/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {AccountConstants} from './account-actions';
import Validations from '../common/validations/validations';
import {Dispatcher} from '../flux-helpers';
import SessionStore from '../session/session-store';

const AccountUpdateValidations = {
    company: {label: 'account.tab.info.company', validations: [Validations.required]},
    email: {label: 'account.tab.info.email', validations: [Validations.required, Validations.email]},
    lastName: {label: 'account.tab.info.lastname', validations: [Validations.required]},
    name: {label: 'account.tab.info.firstname', validations: [Validations.required]}
};

class AccountStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            activeTab: AccountConstants.ACCOUNT.TAB.INFO,
            account: Immutable.Map(),
            showUpdateModal: false,
            updatedAccount: Immutable.Map()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case AccountConstants.ACCOUNT.CHANGE_LANGUAGE.SUCCESS:
            state = state.setIn(['account', 'defaultLanguageCode'], action.languageCode);
            break;

        case AccountConstants.ACCOUNT.CLEAR:
            state = this.getInitialState();
            break;

        case AccountConstants.ACCOUNT.CLEAR_UPDATE_MODAL:
            state = state.set('updatedAccount', state.get('account'));
            break;

        case AccountConstants.ACCOUNT.CLONE:
            state = state.merge({
                account: SessionStore.getState().get('authUser'),
                updatedAccount: SessionStore.getState().get('authUser')
            });
            break;

        case AccountConstants.ACCOUNT.SET_TAB.SUCCESS:
            state = state.set('activeTab', action.activeTab);
            break;

        case AccountConstants.ACCOUNT.SHOW_UPDATE_MODAL:
            state = state.set('showUpdateModal', action.flag);
            break;

        case AccountConstants.ACCOUNT.UPDATE_ATTR:
            state = state.setIn(['updatedAccount', ...action.attr.split('.')], action.value);
            break;

        case AccountConstants.ACCOUNT.UPDATE:
            state = state.setIn(action.attr.split('.'), action.value);
            break;
        }

        return state;
    }
}
export default new AccountStore(Dispatcher);

export {
    AccountUpdateValidations
};
