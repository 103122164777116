/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SignUpConstants} from './signup-actions';
import Validations from '../common/validations/validations';
import {Dispatcher} from '../flux-helpers';
import {SessionConstants} from '../session/session-actions';

const FYCValidations = {
    country: {label: 'signup.form.label.country', validations: [Validations.required]},
    email: {label: 'signup.form.label.email', validations: [Validations.email, Validations.required, Validations.max(250)]},
    lastName: {label: 'signup.form.label.lastName', validations: [Validations.required, Validations.max(50)]},
    firstName: {label: 'signup.form.Zlabel.name', validations: [Validations.required, Validations.max(50)]},
};

const StepOneValidations = {
    country: {label: 'signup.form.label.country', validations: [Validations.required]},
    email: {label: 'signup.form.label.email', validations: [Validations.email, Validations.required, Validations.max(250)]},
    name: {label: 'signup.form.Zlabel.name', validations: [Validations.required, Validations.max(50)]},
    lastName: {label: 'signup.form.label.lastName', validations: [Validations.required, Validations.max(50)]},
    middleName: {label: 'signup.form.label.middleName', validations: [Validations.max(50)]},
};

const StepTwoValidations = {
    company: {label: 'signup.form.label.company', validations: [Validations.required]},
    jobTitle: {label: 'signup.form.label.jobTitle', validations: [Validations.required, Validations.max(50)]},
    repUserId: {label: 'signup.form.label.wbContact', validations: [Validations.required]},
    requestAdditionalSecurity: {label: 'signup.form.label.request-additional-security', validations: [Validations.required]},
    referrerEmail: {label: 'signup.form.label.referrers-email', validations: [Validations.email, Validations.max(50)]},
};
// TODO: check if this is actually being used
/* istanbul ignore next */
const ExtraStepOneValidations = {
    other: {
        validations: [
            Validations.custom(
                function() {
                    return 'Other can not be left blank.';
                },
                function() {
                    let isValid = true;
                    if (store.getState().get('accessTypeSelected').findIndex(type => type.get('id') === SignUpConstants.ACCESS_TYPE.OTHER.id) !== -1) {
                        if (!store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.OTHER.id]) || store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.OTHER.id]) === '') {
                            isValid = false;
                        }
                    }
                    return isValid;
                }
            ),
            Validations.custom(
                function() {
                    return 'Business Justification can not be left blank.';
                },
                function() {
                    let isValid = true;
                    if (store.getState().get('accessTypeSelected').findIndex(type => type.get('id') !== SignUpConstants.ACCESS_TYPE.VIDEOS_TO_VIEW.id) !== -1 &&
                        store.getState().get('accessTypeSelected').findIndex(type => type.get('id') !== SignUpConstants.ACCESS_TYPE.STATION_DOCUMENTS.id) !== -1) {
                        if (!store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.BUSINESS_JUSTIFICATION.id]) || store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.BUSINESS_JUSTIFICATION.id]) === '') {
                            isValid = false;
                        }
                    }
                    return isValid;
                }
            ),
            Validations.custom(
                function() {
                    return 'Station Callsign(s) can not be left blank.';
                },
                function() {
                    let isValid = true;
                    if (store.getState().get('accessTypeSelected').findIndex(type => type.get('id') === SignUpConstants.ACCESS_TYPE.STATION_DOCUMENTS.id) !== -1) {
                        if (!store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.STATION_DOCUMENTS.id]) || store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.STATION_DOCUMENTS.id]) === '') {
                            isValid = false;
                        }
                    }
                    return isValid;
                }
            ),
            Validations.custom(
                function() {
                    return 'One or more characters introduced into Other field is not valid.';
                },
                function() {
                    let isValid = true;
                    if (store.getState().get('accessTypeSelected').findIndex(type => type.get('id') === SignUpConstants.ACCESS_TYPE.OTHER.id) !== -1) {
                        if (/<|>|\\|"|%|\||;|\[|\]|\\\/|\r|\n/.test(store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.OTHER.id]))) {
                            isValid = false;
                        }
                    }
                    return isValid;
                }
            )]
    },
    aditionalPartners: {
        validations: [Validations.custom(
            function() {
                return 'Please select a partner.';
            },
            function() {
                let isValid = true;
                if (store.getState().get('accessTypeSelected').findIndex(type => type.get('id') === SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id) !== -1) {
                    if (!store.getState().getIn(['additionalAccess', SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id]).size) {
                        isValid = false;
                    }
                }
                return isValid;
            }
        )]
    },
};

class SignUpStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            accessType: Immutable.fromJS(SignUpConstants.toArray('ACCESS_TYPE')),
            accessTypeSelected: Immutable.List(),
            additionalAccess: Immutable.Map({
                [SignUpConstants.ACCESS_TYPE.VIDEOS_TO_VIEW.id]: false,
                [SignUpConstants.ACCESS_TYPE.VIDEOS_PLACE_ORDERS.id]: false,
                [SignUpConstants.ACCESS_TYPE.PHOTOS_TO_VIEW.id]: false,
                [SignUpConstants.ACCESS_TYPE.PHOTOS_TO_DOWNLOAD.id]: false,
                [SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id]: Immutable.List(),
                [SignUpConstants.ACCESS_TYPE.TITLE_METADATA.id]: false,
                [SignUpConstants.ACCESS_TYPE.OTHER.id]: '',
            }),
            signup: Immutable.Map(),
        });
    }

    getFYCValidations() {
        return Validations.validate(this.getState().get('signup'), FYCValidations);
    }

    getStepOneValidations() {
        return Validations.validate(this.getState().get('signup'), StepOneValidations);
    }

    getStepTwoValidations() {
        return [
            ...Validations.validate(this.getState().get('signup'), StepTwoValidations),
            ...Validations.validate(this.getState().get('additionalAccess'), ExtraStepOneValidations)
        ];
    }

    reduce(state, action) {
        switch (action.actionType) {
        case SignUpConstants.ADDITIONAL_ACCESS_UPDATE:
            state = state.setIn(action.attr.split('.'), action.value);
            break;
        case SessionConstants.DOMAIN_ZERO_WITHOUT_ACCOUNT.GET.SUCCESS:
            const account = action.account.toJS();
            // This is becuase the API objects are similar but not equal.
            const {firstName, company} = account;
            const accountData = {
                ...account,
                name: firstName,
                company: {
                    id: company,
                    name: company
                }
            };
            ['firstName', 'primaryPhone'].forEach(prop => delete accountData[prop]);
            state = state.merge({
                signup: Immutable.fromJS(accountData)
            });
            break;
        case SignUpConstants.UPDATE:
            state = state.setIn(['signup', action.attr], action.value);
            break;
        }
        return state;
    }
}

const store = new SignUpStore(Dispatcher);
export default store;
export {FYCValidations, StepOneValidations, StepTwoValidations};
