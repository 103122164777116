/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';

import Analytics from '../analytics';
import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';

const CONSTANTS = {
    SHARE: {
        CLEAR: 'share_asset_constants.clear',
        UPDATE_SHARE_PROFILE: 'share_asset_constants.update_share_profile',
        GENERATE_PREAUTH_LINK: {
            SUCCESS: 'share_asset_constants.generate_preauth_link.success'
        }
    }
};

class ShareAssetActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHARE.CLEAR
        });
    }

    generatePreauthShareLink(asset, assetType, titleId, email, daysActive, views) {
        return this._share(asset, assetType, titleId, email, daysActive, views, true, null, true);
    }

    _share(asset, assetType, titleId, email, daysActive, views, preAuthenticated, userId, generateLink) {
        return Request.post('web/share-link')
            .send({
                assetType: assetType,
                assetId: asset.get('assetId'),
                titleId: preAuthenticated ? null : titleId,
                expirationDate: Moment().add(daysActive, 'days').format(),
                numberOfUses: views,
                preAuthenticated: preAuthenticated ? 1 : 0,
                shareUsers: [{
                    userId: userId,
                    emailAddress: email
                }]
            }).then(resp => {
                Analytics.assetShareEvent(asset.get('assetId'), assetType, titleId, resp.body[0].id, views, daysActive, email);

                if (generateLink) {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.SHARE.GENERATE_PREAUTH_LINK.SUCCESS,
                        details: Immutable.fromJS(resp.body),
                    });
                } else {
                    NotificationActions.showAlertSuccess('titles.share-modal.success');
                }
            })
            .catch( err => {
                NotificationActions.showAlertDanger('titles.share-modal.error');
                throw err;
            });
    }

    share(asset, assetType, titleId, email, daysActive, views, preAuthenticated, userId) {
        if (titleId) {
            return this._share(asset, assetType, titleId, email, daysActive, views, preAuthenticated, userId);
        }

        let tid;
        Request.get(`asset/${asset.get('assetId')}/title`).then(res => {
            if (res.body && res.body.length > 0) {
                tid = res.body[0].titleId;
            }
            return this._share(asset, assetType, tid, email, daysActive, views, preAuthenticated, userId);
        }).catch(err => {
            throw err;
        });

    }

    updateShareProfile(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.SHARE.UPDATE_SHARE_PROFILE,
            attr: attr,
            value: value
        });
    }

}

let actions = new ShareAssetActions();

export {
    actions as ShareAssetActions,
    CONSTANTS as ShareAssetConstants,
};
