/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {STATION_SUBSCRIPTION_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import QueryString from 'query-string';

import {Dispatcher} from '../../../flux-helpers';
import {NotificationActions} from '../../../notification/notification-actions';
import Request from '../../../request';

const CONSTANTS = {
    CLEAR: 'subscription.clear',
    GET: {
        START: 'subscription.get.start',
        SUCCESS: 'subscription.get.success',
        TITLE: 'subscription.get.title',
        USER: 'subscription.get.user'
    },
    SUBSCRIPTION_CONTENT_TYPES: STATION_SUBSCRIPTION_CT_TYPES,
    UNSUBSCRIBE: {
        CLEAR: 'subscription.remove.clear',
        SET: 'subscription.remove.set'
    },
    UPDATE_SUBSCRIPTION: 'subscription.update',
    UPDATE_SUBSCRIPTION_USER: 'subscription.update_user',
};

class SubscriptionActions {

    cancelRemoveSubscription() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UNSUBSCRIBE.CLEAR,
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    doRemoveSubscription(toUnsubscribeClient) {
        Request.del(`title/${toUnsubscribeClient.titleId}/user-subscription/${toUnsubscribeClient.userId}/subscription-type/${toUnsubscribeClient.subscriptionContentType}`).then(() => {
            NotificationActions.showAlertSuccess('orders.tab.subscriptions.unsubscribe-client.success');
            return this.getSubscriptions(
                toUnsubscribeClient.userId,
                toUnsubscribeClient.subscriptionContentType,
                toUnsubscribeClient.sortFieldName,
                toUnsubscribeClient.sortDirection,
                toUnsubscribeClient.size,
                toUnsubscribeClient.offset
            );
        }).catch((err) => {
            NotificationActions.showAlertDanger('orders.tab.subscriptions.unsubscribe-client.error');
            throw err;
        }).finally(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.UNSUBSCRIBE.CLEAR
            });
            return;
        });
        return;
    }

    getSubscriptions(userId, subscriptionContentTypes = [], sortFieldName = 'subscription_date', sortDirection = 'desc', size = 20, offset = 0) {
        let queryParams = Object.assign(
            {},
            {
                'subscription-content-type': subscriptionContentTypes,
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'size': size,
                'offset': offset
            }
        );

        Request.get(`user/${userId}/title-user-subscription?${QueryString.stringify(queryParams)}`).then(subscriptions => {
            if (!subscriptions.body.results) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    subscriptions: {}
                });
                return;
            }

            const subs = Immutable.fromJS(subscriptions.body.results.reduce((s, t) => {
                if (!s[t.subscriptionContentType]) {
                    s[t.subscriptionContentType] = {
                        elements: [],
                        totalCount: 0,
                        offset: 0
                    };
                }
                s[t.subscriptionContentType].elements.push(t);
                s[t.subscriptionContentType].totalCount = subscriptions.body.totalCount;
                s[t.subscriptionContentType].offset = subscriptions.body.offset;

                return s;
            }, Object.values(CONSTANTS.SUBSCRIPTION_CONTENT_TYPES).reduce((reductor, contentType) => {
                reductor[contentType.id] = {
                    elements: [],
                    totalCount: 0,
                    offset: 0
                };
                return reductor;
                // returned example:
                // "1": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "2": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "3": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "4": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "5": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // }
            }, {})));

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                subscriptions: subs
            });
        }).catch(err => {
            throw err;
        });
        return;
    }

    getSubscriptionsByTitle(userId, titleId, subscriptionContentTypes = [], sortFieldName = 'subscription_date', sortDirection = 'desc', size = 20, offset = 0) {
        let queryParams = Object.assign(
            {},
            {
                'subscription-content-type': subscriptionContentTypes,
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection,
                'size': size,
                'offset': offset
            }
        );

        Request.get(`title/${titleId}/user-subscription/${userId}?${QueryString.stringify(queryParams)}`).then(subscriptions => {
            if (!subscriptions.body) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    subscriptions: {}
                });
                return;
            }

            const subs = Immutable.fromJS(subscriptions.body.reduce((s, t) => {
                if (!s[t.subscriptionContentType]) {
                    s[t.subscriptionContentType] = {
                        elements: [],
                        totalCount: 0,
                        offset: 0
                    };
                }
                s[t.subscriptionContentType].elements.push(t);
                s[t.subscriptionContentType].totalCount = subscriptions.body.totalCount;
                s[t.subscriptionContentType].offset = subscriptions.body.offset;

                return s;
            }, Object.values(CONSTANTS.SUBSCRIPTION_CONTENT_TYPES).reduce((reductor, contentType) => {
                reductor[contentType.id] = {
                    elements: [],
                    totalCount: 0,
                    offset: 0
                };
                return reductor;
                // returned example:
                // "1": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "2": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "3": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "4": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // },
                // "5": {
                //     "elements": [],
                //     "totalCount": 0,
                //     "offset": 0
                // }
            }, {})));

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                subscriptions: subs
            });
        }).catch(err => {
            throw err;
        });
        return;
    }

    update(titleId, userId, suggestedByUserId, subscriptionContentTypeId, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_SUBSCRIPTION,
            titleId: titleId,
            userId,
            suggestedByUserId,
            subscriptionContentTypeId,
            value
        });
    }

    updateUser(userId, suggestedByUserId) {
        // Update all subscriptions to have the proper userId and suggestedByUserId values. Only used when subscribing client accounts
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE_SUBSCRIPTION_USER,
            userId,
            suggestedByUserId,
        });
    }

    removeSubscription(titleId, userId, subscriptionContentType, sortFieldName, sortDirection, size, offset) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UNSUBSCRIBE.SET,
            titleId: titleId,
            userId: userId,
            subscriptionContentType: subscriptionContentType,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection,
            size: size,
            offset: offset
        });
    }
}

let actions = new SubscriptionActions();

export {
    actions as SubscriptionActions,
    CONSTANTS as SubscriptionConstants,
};
