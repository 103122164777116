/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import {PlayerActions} from '../player/player-actions';
import Request from '../request';
import mfa from '../session/mfa';
import SessionStore from '../session/session-store';

const CONSTANTS = {
    GET: {
        ERROR: 'dsd_constants.get.error',
        START: 'dsd_constants.get.start',
        SUCCESS: 'dsd_constants.get.success'

    },
    VIDEOS: {
        ADD: 'dsd_constants.videos.add'
    },
    GET_VIDEO_STREAMS: 'dsd_constants.get_video_streams',
    TAB: {
        INFO: 'Info',
        TALENT: 'Talent'
    },
    TALENTS_BY_TITLE: {
        GET: {
            ERROR: 'dsd_constants.talents_by_title.get.error',
            START: 'dsd_constants.talents_by_title.get.start',
            SUCCESS: 'dsd_constants.talents_by_title.get.success'
        }
    }
};

class DigitalSalesDecksActions {

    get(uuid) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START
        });

        let titles = [];

        Request.get(`work-order/guid/${uuid}`).then(res => {

            const titleRequests = res.body.workOrderTitles.sort(
                (a, b) => a.displayOrder - b.displayOrder
            ).map((title) => Request.get(`title/${title.titleId}`));

            return Promise.all(titleRequests);
        }).then(responses => {
            //STUDIO-13305: only keep the active titles responses
            const activeTitlesResponses = responses.filter(response => response.body.active !== 0);
            titles = activeTitlesResponses.map(response => response.body);

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                titles: Immutable.fromJS(titles)
            });

            const talentsRequests = titles.map((title) => Request.get(`title/${title.id}/talent`).then((talentResponse) => {
                return {titleId: title.id, talentResponse};
            }));
            return Promise.all(talentsRequests);
        }).then(responses => {
            const talentData = responses.map(response => {
                const titleId = response.titleId;
                // Group talent by role.
                const talent = Immutable.fromJS(response.talentResponse.body.reduce((r, t) => {
                    if (r[t.roleInTitle]) {
                        r[t.roleInTitle].push(t);
                    } else {
                        r[t.roleInTitle] = [t];
                    }
                    return r;
                }, {}));

                return {
                    titleId,
                    talent
                };
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.TALENTS_BY_TITLE.GET.SUCCESS,
                talentData: Immutable.fromJS(talentData)
            });

            const assetRequests = titles.map((title) => Request.get(`title/${title.id}/web/asset?asset-type=2&offset=0&size=1`));
            return Promise.all(assetRequests);
        }).then(responses => {
            // Map and filter because some titles don't have assets
            const assets = responses.map(response => {
                const asset = response.body.results[0];
                if (!asset) {return;}
                return {
                    assetId: asset.assetId,
                    titleId: asset.titleId
                };
            }).filter(a => !!a);

            return assets.map(asset => {
                return Promise.all([
                    Request.get(`asset/video/${asset.assetId}`),
                    Request.get('asset/video/thumbnailURL').query({'video-id': asset.assetId}),
                ]).spread((videoRes, thumbnailRes) => {
                    let video = videoRes.body;
                    if (thumbnailRes.body && thumbnailRes.body[0]) {
                        video.thumbnails = thumbnailRes.body[0].thumbnailList;
                    }
                    video.assetId = asset.assetId;
                    video.titleId = asset.titleId;

                    Dispatcher.dispatch({
                        actionType: CONSTANTS.VIDEOS.ADD,
                        video: Immutable.fromJS(video)
                    });
                });
            });
        }).catch((err) => {
            switch (err.status) {
            case 400:
            case 404:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR
                });
                // FIXME: need a not found page that doesn't break with new bootstrap
                // or use CSS modules to isolate scope.
                window.open('/errors/not-found', '_self');
                break;
            }
            throw err;
        });
    }
    /* istanbul ignore next */
    prepareVideo(video, videoIndex) {
        const videoId = video.get('id');
        const forensicUrlRequired = video.get('forensicUrlRequired');

        let mfaPromise = Promise.resolve();
        if (video.get('mfaRequired') || video.get('children', Immutable.List()).some(vc => vc.get('mfaRequired'))) {
            mfaPromise = mfa.RequestMFA(
                SessionStore.getState().getIn(['authUser', 'id']),
                false
            ).catch(err => {
                switch (true) {
                case err instanceof mfa.MFARejectedError:
                    NotificationActions.showAlertDanger('player.errors.mfa.rejected');
                    break;
                case err instanceof mfa.MFARequestError:
                    NotificationActions.showAlertDanger('player.errors.mfa.status');
                    break;
                case err instanceof mfa.MFAUnknownStatusError:
                case err instanceof mfa.MFAUnregisteredError:
                    break;
                default:
                    NotificationActions.showAlertDanger('player.errors.mfa.400');
                    break;
                }

                // Set the error as default prevented so that the next handler
                // doesn't add alerts on top of existing alerts.
                err.defaultPrevented = true;
                throw err;
            });
        }

        mfaPromise.then(() => {
            let streamRequest = Request.get('asset/video/streamURL').query({'video-id': videoId});
            if (forensicUrlRequired) {
                streamRequest = Request.get(`asset/video/${videoId}/forensic-url`);
            }

            return streamRequest;
        }).then(streamRes => {
            let streams = Immutable.fromJS(streamRes.body[0]);
            if (forensicUrlRequired) {
                streams = Immutable.fromJS(streamRes.body);
            }

            if (!streams) {
                // No streams for this video.
                NotificationActions.showAlertDanger('player.errors.no-sources');
                let err = new Error(`Video ${videoId} does not have any playable sources.`);
                err.defaultPrevented = true;
                throw err;
            }

            let {hasDRM, err} = PlayerActions._hasDRM(streams, true);
            if (err) {
                // Throw error (instead of return) to reject the promise.
                throw err;
            }

            let tokenResponse;
            if (hasDRM) {
                streams = streams.set('assetId', videoId);
                tokenResponse = PlayerActions._requestToken(streams);
            }

            return Promise.props({
                streams,
                tokenResponse,
                watchlist: PlayerActions.getWatchlistDetail(videoId)
            });
        }).then(data => {
            let token;
            if (data.tokenResponse) {
                token = Immutable.fromJS(data.tokenResponse.body);
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET_VIDEO_STREAMS,
                streams: data.streams,
                token,
                videoId,
                videoIndex,
                titleId: video.get('titleId')
            });
            return;
        }).catch(err => {
            if (!err.defaultPrevented) {
                NotificationActions.showAlertDanger('player.errors.streams.400');
            }
            throw err;
        });
    }
}

let actions = new DigitalSalesDecksActions();

export {
    actions as DigitalSalesDecksActions,
    CONSTANTS as DigitalSalesDecksConstants
};
