/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SubscribedContentConstants} from './subscribed-content-actions';
import {Dispatcher} from '../../../flux-helpers';

class SubscriptionStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            activePageLabel: 1,
            assets: Immutable.Map(),
            offset: 0,
            size: 0,
            subscribedContent: Immutable.Map({
                details: Immutable.List()
            }),
            totalCount: 0
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case SubscribedContentConstants.ASSET.GET.SUCCESS:
            state = state.setIn(['assets', action.asset.get('id')], action.asset);
            break;

        case SubscribedContentConstants.GET.SUCCESS:
            state = state.setIn(['subscribedContent', 'details'], action.subscribedContent);
            state = state.set('offset', action.offset);
            state = state.set('size', action.size);
            state = state.set('totalCount', action.totalCount);
            break;
        }

        return state;
    }
}

export default new SubscriptionStore(Dispatcher);
