/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DownloadHistoryConstants} from './download-history-actions';
import {Dispatcher} from '../../../flux-helpers';

class DownloadHistoryStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            activePageLabel: 1,
            assets: Immutable.Map(),
            history: Immutable.List(),
            totalCount: 0,
            loading: false
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case DownloadHistoryConstants.CLEAR:
            state = this.getInitialState();
            break;

        case DownloadHistoryConstants.GET.ERROR:
            state = state.set('loading', false);
            break;

        case DownloadHistoryConstants.GET.START:
            state = state.set('loading', true);
            break;

        case DownloadHistoryConstants.GET.SUCCESS:
            state = state.set('history', action.history)
                .set('loading', false)
                .set('totalCount', action.totalCount);
            break;

        case DownloadHistoryConstants.ASSET.GET.SUCCESS:
            // Find history item, update details
            state = state.setIn(['assets', action.asset.get('id')], action.asset);
            break;
        }

        return state;
    }
}

export default new DownloadHistoryStore(Dispatcher);
