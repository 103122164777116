/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import Config from '../config/config';
import {Dispatcher} from '../flux-helpers';
import Request from '../request';

let additionalPartners = {};
Object.keys(Config.Partners).forEach((k) => {
    const p = Immutable.fromJS(Config.Partners[k]);
    if (p.get('showInAdditionalPartners', false)) {
        additionalPartners[k] = p.toJS();
    }
});

const CONSTANTS = {
    ADDITIONAL_PARTNERS: additionalPartners,
    LOAD: 'partner.load',
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k]);
    }
};

class PartnerActions {

    get(offset, size) {
        Request.get('partner')
            .query({
                offset,
                size
            })
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.LOAD,
                    offset: res.body.offset,
                    partners: Immutable.fromJS(res.body.results.filter(p => {
                        return !!Config.Partners[p.clientId];
                    })).sortBy(p => p.get('name')),
                    size: res.body.size,
                    total: res.header['wbtv-total-count']
                });
                return;
            }).catch(err => {
                throw err;
            });

        return;
    }
}

let actions = new PartnerActions();

export {
    actions as PartnerActions,
    CONSTANTS as PartnerConstants
};
