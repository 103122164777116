/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';

const CONSTANTS = {
    LANGUAGES: {
        GET: {
            SUCCESS: 'title_language_availability.languages.get.success'
        }
    },
    TITLE_LANGUAGE_AVAILABILITY: {
        GET: {
            START: 'title_language_availability.get.start',
            SUCCESS: 'title_language_availability.get.success'
        }
    }
};

class TitleLanguageAvailabilityActions {
    // eslint-disable-next-line default-param-last
    get(languages = [], title, offset = 0, size = 15) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_LANGUAGE_AVAILABILITY.GET.START
        });

        Request.get(
            'title/language-availability-summary'
        ).query({
            'language-list': languages,
            title,
            offset,
            size
        }).then(titleLanguageAvailabilityResponse => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_LANGUAGE_AVAILABILITY.GET.SUCCESS,
                titleLanguageAvailability: Immutable.fromJS(titleLanguageAvailabilityResponse.body)
            });

            return;
        }).catch(e => {
            NotificationActions.showAlertDanger('title-language-availability.get.error');
            throw e;
        });
    }

    getLanguages() {
        Request.get(
            'title/language-availability-list'
        ).then(languagesRes => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.LANGUAGES.GET.SUCCESS,
                languages: Immutable.fromJS(languagesRes.body)
            });

            return;
        });
    }
}

let actions = new TitleLanguageAvailabilityActions();

export {
    actions as TitleLanguageAvailabilityActions,
    CONSTANTS as TitleLanguageAvailabilityConstants
};
