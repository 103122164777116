/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';
import {Subject} from 'rxjs';
import {filter, merge, takeUntil} from 'rxjs/operators';

import {Dispatcher} from '../flux-helpers';
import Request from '../request';
import {ObservableRoute, RouterActions} from '../router/router-actions';
import {TitleConstants} from '../titles/title-actions';

const CONSTANTS = {
    TALENT: {
        AKA: {
            GET: {
                SUCCESS: 'talent_constants.talent.aka.get.success'
            }
        },
        ASSETS: {
            GET: {
                SUCCESS: 'talent_constants.talent.asset.get.success'
            }
        },
        ASSET_TYPE: {
            IMAGE: 1
        },
        GET: {
            SUCCESS: 'talent_constants.talent.get.success'
        }
    },
    CLEAR: 'talent_constants.clear',
    ROLE: {
        ACTOR: 1,
        DIRECTOR: 2,
        PRODUCER: 3,
        VOICE_TALENT: 4,
        HOST: 5,
        WRITER: 6,
        MUSICIAN: 7,
        CHOREOGRAPHER: 8,
        EXECUTIVE_PRODUCER: 9,
        GUEST_STAR: 10,
        AUTHOR: 11,
        ANIMATED_CHARACTER: 12,
        MISC_CREW: 13,
        CREATOR: 14
    },
    TITLES: {
        GET: {
            SUCCESS: 'talent_constants.titles.get.success'
        },
    }
};

class TalentActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getAKAs(talentId, canceler) {
        let rxAKA = new Subject();
        Request.get(`talent/${talentId}/aka`).canceler(canceler).then(akaRes => {
            rxAKA.next({
                actionType: CONSTANTS.TALENT.AKA.GET.SUCCESS,
                aka: Immutable.fromJS(Object.values(akaRes.body).map(ak => ak.fullName))
            });
            return;
        }).catch(err => {
            rxAKA.error(err);
            throw err;
        });
        return rxAKA;
    }

    get(id, userId) {

        let canceler = ObservableRoute.pipe(filter(x => {
            let cancel = x.pathname.indexOf(`talent/${id}`) === -1;
            return cancel;
        }));

        let rxTalent = this._get(
            id,
            userId,
            canceler
        );

        let rxAKAs = this.getAKAs(id, canceler);

        rxTalent.pipe(
            merge(rxAKAs),
            takeUntil(canceler)).subscribe(
            (event) => {
                Dispatcher.dispatch(event);
            },
            (/*error*/) => {
                //todo handle error
            }
        );
    }

    _get(id, userId, canceler) {
        let rxTalent = new Subject();
        Request.get(`talent/${id}`).canceler(canceler).then(talentRes => {
            let talent = talentRes.body;
            rxTalent.next({
                actionType: CONSTANTS.TALENT.GET.SUCCESS,
                talent: Immutable.fromJS(talent)
            });

            let promises = {
                // Get the first eight images to display. Also, get the images by id
                // so that they display with the thumbnails and they deep link correctly.
                images: Request.get(`talent/${id}/web/asset`).query({
                    'asset-type': CONSTANTS.TALENT.ASSET_TYPE.IMAGE,
                    jp: userId,
                    offset: 0,
                    size: 8,
                    'sort-field-name': 'assetorder',
                    'sort-direction': 'asc'
                }).canceler(canceler).then(res => {
                    return Promise.all(
                        res.body.results.map(i => Request.get(`asset/image/${i.assetId}`))
                    );
                }).then(imagesRes => {
                    return {
                        body: {
                            results: imagesRes.map(iRes => {
                                let image = iRes.body;
                                image.assetId = image.id;
                                return image;
                            })
                        }
                    };
                }),
            };
            return Promise.props(promises);
        }).then(assetsRes => {
            rxTalent.next({
                actionType: CONSTANTS.TALENT.ASSETS.GET.SUCCESS,
                images: Immutable.fromJS(assetsRes.images.body.results)
            });

            return;
        }).catch(err => {
            rxTalent.error(err);
            switch (err.status) {
            case 400:
            case 404:
                RouterActions.notFound();
                break;
            }
            throw err;
        });

        return rxTalent;
    }

    getTitles(id) {
        let titles;
        Request.get(`talent/${id}/web/title-role`).then( res => {
            titles = res.body.map(title => ({...title, categoryGroupMap: TitleConstants.categoryGroupMap[title.category]}));

            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLES.GET.SUCCESS,
                titles: Immutable.fromJS(titles)
            });
        }).catch(err => {
            // TODO notify
            throw err;
        });
    }

}

const actions = new TalentActions();

export {
    actions as TalentActions,
    CONSTANTS as TalentConstants
};
