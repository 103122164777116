/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import {Dispatcher} from '../../../flux-helpers';
import {NotificationActions} from '../../../notification/notification-actions';
import Request from '../../../request';

const CONSTANTS = {
    ASSET: {
        GET:{
            SUCCESS: 'asset.get.success'
        }
    },
    CLEAR: 'subscribedContent.clear',
    GET: {
        SUCCESS: 'subscribedContent.get.success',
    }
};

class SubscriptionActions {
    getContent(userId, offset) {
        let pagination = {
            offset: offset || 0,
            size: 5
        };
        return Request.get(`user/${userId}/subscribed-content/detail`).query(pagination).then(details => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset: details.body.offset,
                size: details.body.size,
                subscribedContent: Immutable.fromJS(details.body.results),
                totalCount: details.body.totalCount
            });
            return this.getAssets(details.body.results);
        }).catch(err => {
            throw err;
        });
    }

    getAssets(details) {
        if (details) {
            return details.map(detail => {
                return Request.get(`asset/${AssetTypeConstants.ASSET_TYPE_NAMES[detail.assetType]}/${detail.assetId}`)
                    .then(asset => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.ASSET.GET.SUCCESS,
                            asset: Immutable.fromJS(asset.body)
                        });
                    });
            });
        }
    }

    removeSubscribedContent(userId, assetType, assetId, offset) {
        return Request.del(`user/${userId}/subscribed-content/${assetType}/${assetId}`)
            .then(() => {
                NotificationActions.showAlertSuccess('orders.tab.subscribed-content.delete.success');
                return this.getContent(userId, offset);
            })
            .catch((err) => {
                NotificationActions.showAlertDanger('orders.tab.subscribed-content.delete.error');
                throw err;
            });
    }
}

let actions = new SubscriptionActions();

export {
    actions as SubscribedContentActions,
    CONSTANTS as SubscribedContentConstants,
};
