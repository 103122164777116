/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';

const CONSTANTS = {
    CLEAR: 'list-documents.clear',
    GET: 'list-documents.get',
    CONTENT_TYPES: {
        EDIT_NOTES: 30,
        LANGUAGE_AVAILABILITY_CHART: 29,
    },
    /**
     * publishing list names migth change and ids migth not belong to
     * initial publishing list name defined here
     */
    PUBLISHING_LIST: {
        ADULTSWIM_PRESS: 1481,
        CARTOON_NETWORK_PRESS: 1545,
        CARTOONITO_PRESS: 1910,
        ACQUISITIONS: 340,
        COUNTRY_OVERVIEWS: 1082,
        PRESS_SITE_RELEASES: 1265,
        PRODUCT_REPORTS: 342,
        THEATRICAL_GRIDS: 343,
        US_AIR_DATES: 341
    }
};

class ListDocumentsActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    get(publishingListId) {
        let list;

        Request.get(`web/publish-list/${publishingListId}`).then(plRes => {
            list = plRes.body;
            return Request.get(`web/publish-list/${list.id}/my-items`);
        }).then(itemsRes => {
            list.items = itemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);
            // Add the items to the publishing list.
            return Promise.all(
                list.items.map(
                    item => Request.get(`asset/document/${item.assetId}`).then(res => res).catch(err => {
                        // Catch errors if asset is not found.
                        console.error(err);
                        return {body: {}};
                    })
                )
            );
        }).then(itemsRes => {
            list.items = list.items.map((item, index) => {
                item.asset = itemsRes[index].body;
                return item;
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET,
                list: Immutable.fromJS(list),
                key: publishingListId
            });

            return;
        }).catch(err => {
            NotificationActions.showAlertDanger('list-documents.actions.get.error');

            throw err;
        });
        return;
    }

    getByDocumentType(documentType) {
        Request.get('asset/web/document').query({
            'content-type': documentType,
            size: 9999,
        }).then((res) => {
            let ret = {
                items: res.body.results.map((item) => {
                    return {
                        asset: {
                            createdDate: item.createdDate,
                            name: item.assetName
                        },
                        assetId: item.id,
                        createdDate: item.createdDate,
                        description: item.assetName,
                    };
                })
            };

            Dispatcher.dispatch({
                actionType: CONSTANTS.GET,
                list: Immutable.fromJS(ret),
                key: documentType
            });
        }).catch((err) => {
            NotificationActions.showAlertDanger('list-documents.actions.get.error');

            throw err;
        });
    }
}

let actions = new ListDocumentsActions();

export {
    actions as ListDocumentsActions,
    CONSTANTS as ListDocumentsConstants,
};
