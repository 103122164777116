/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import Request from '../../request';

const CONSTANTS = {
    GET: {
        ERROR: 'vams.get.error',
        START: 'vams.get.start',
        SUCCESS: 'vams.get.success'
    }
};

class VamActions {

    getVamPublishedList(publishListId) {
        Dispatcher.dispatch({actionType: CONSTANTS.GET.START});

        Request.get(`title/vam-status/${publishListId}`).then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                vamList: Immutable.fromJS(response.body)
            });
        }).catch(err => {
            Dispatcher.dispatch({actionType: CONSTANTS.GET.ERROR});
            NotificationActions.showAlertDanger('footer.navmenu.dropdown.internal.item.vam.load-error');
            throw err;
        });
    }
}

let actions = new VamActions();

export {
    actions as VamActions,
    CONSTANTS as VamConstants,
};
