/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TalentConstants} from './talent-actions';
import {Dispatcher} from '../flux-helpers';

class TalentStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            aka: Immutable.List(),
            assets: Immutable.Map({
                images: Immutable.List()
            }),
            talent: Immutable.Map(),
            titles: Immutable.List()
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case TalentConstants.CLEAR:
            state = this.getInitialState();
            break;
        case TalentConstants.TALENT.AKA.GET.SUCCESS:
            state = state.set('aka', action.aka);
            break;
        case TalentConstants.TALENT.GET.SUCCESS:
            state = state.set('talent', action.talent);
            break;
        case TalentConstants.TALENT.ASSETS.GET.SUCCESS:
            state = state.mergeIn(['assets'], {
                images: action.images
            });
            break;
        case TalentConstants.TITLES.GET.SUCCESS:
            state = state.set('titles', action.titles);
            break;

        }
        return state;
    }
}

export default new TalentStore(Dispatcher);
