/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {DownloadConstants} from './download-actions';
import {Dispatcher} from '../../flux-helpers';


class DownloadStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            downloadExecution: Immutable.fromJS({
                statusCounter: 0,
            }),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case DownloadConstants.CLEAR:
            state = this.getInitialState();
            break;

        case DownloadConstants.EXECUTION_ID.GET.SUCCESS:
            state = state.mergeIn(['downloadExecution'], {
                id: action.downloadExecutionId,
                running: true,
                status: 2
            });
            break;

        case DownloadConstants.EXECUTION_STATUS.GET.END:
            state = state.setIn(['downloadExecution', 'running'], false);
            break;

        case DownloadConstants.EXECUTION_STATUS.GET.SUCCESS:
            state = state.mergeIn(['downloadExecution'], {
                fileName: action.fileName,
                preparingZip: action.processed === action.total,
                processed: action.processed,
                progressMessage: action.progressMessage,
                status: action.status,
                statusCounter: (state.getIn(['downloadExecution', 'statusCounter']) + 1) % 4,
                total: action.total
            });
            break;
        }
        return state;
    }
}

export default new DownloadStore(Dispatcher);
