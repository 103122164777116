/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Config from '../config/config';
import {Actions, Dispatcher} from '../flux-helpers';
import {NotificationActions} from '../notification/notification-actions';
import Request from '../request';
import {RouterActions} from '../router/router-actions';

const CONSTANTS = {
    ACCESS_TYPE: {
        ADDITIONAL_PARTNERS: {id: 'ADDITIONAL_PARTNERS', name: 'Additional Sites', hideFromDropdown: false},
        BUSINESS_JUSTIFICATION: {id: 'BUSINESS_JUSTIFICATION', name: 'Business Justification', hideFromDropdown: true},
        PHOTOS_TO_DOWNLOAD: {id: 'PHOTOS_TO_DOWNLOAD', name: 'Photos - To Download', hideFromDropdown: false},
        PHOTOS_TO_VIEW: {id: 'PHOTOS_TO_VIEW', name: 'Photos - To View', hideFromDropdown: false},
        STATION_DOCUMENTS: {id: 'STATION_DOCUMENTS', name: 'Station Documents', hideFromDropdown: false},
        TITLE_METADATA: {id: 'TITLE_METADATA', name: 'Title Metadata', hideFromDropdown: false},
        VIDEOS_PLACE_ORDERS: {id: 'VIDEOS_PLACE_ORDERS', name: 'Video: Place Orders, Clip Videos', hideFromDropdown: false},
        VIDEOS_TO_VIEW: {id: 'VIDEOS_TO_VIEW', name: 'Video: View Video, WBD Screeners Access', hideFromDropdown: false},
        OTHER: {id: 'OTHER', name: 'Other', hideFromDropdown: false},
    },
    ADDITIONAL_ACCESS_UPDATE: 'signup_actions.additional_access_update',
    UPDATE: 'signup_actions.signup.attribute.update',
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k]);
    }
};

class SignUpActions extends Actions {
    constructor(CONST) {
        super(CONST);
    }

    register(user, partnerId) {
        partnerId = partnerId || Config.Partners.WBTVD.id;
        user.country = user.country.name;

        if (user.company?.name) {
            user.company = user.company.name;
        } else {
            user.company = '';
        }

        if (user.responsibility?.id === 'other') {
            user.responsibility = user.otherResponsibility;
        } else {
            user.responsibility = user.responsibility?.name || '';
        }

        ['otherResponsibility', 'selectedCountry', 'selectedResponsibility'].forEach(p => delete user[p]);


        Request.post('user/register')
            .send(user)
            .set('WBTV-Partner-Id', Config.Partners[partnerId].id)
            .set('WBTV-Partner-Key', Config.Partners[partnerId].key)
            .then(() => {
                RouterActions.redirect('/sign-up/thank-you');
            })
            .catch(err => {
                NotificationActions.showAlertDanger('signup.error');
                throw err;
            });
    }

    signUpFyc(user) {
        user = user.toJS();
        user.country = user.country.name;
        user.emailAddress = user.email;
        user.agreeTerms = user.agreeTerms || false;

        ['selectedCountry', 'email'].forEach(p => delete user[p]);

        Request.post('user/register/anonymous')
            .send(user)
            .set('WBTV-Partner-Id', Config.Partners.WBFYC_EMMY.id)
            .set('WBTV-Partner-Key', Config.Partners.WBFYC_EMMY.key)
            .then(() => {
                RouterActions.redirect('/sign-up/thank-you');
            })
            .catch(err => {
                NotificationActions.showAlertDanger('signup.error');
                throw err;
            });
    }

    signUpStepOne(user, partner) {
        let data = user.toJS();
        // check if user has a valid WB email for skipping step 2 and going directly to thank you page
        // WPB-7661: Turner users must go to step 2 regardless of domain check.
        let skipStep2 = Promise.resolve(false);
        if (!/@turner.com$/.test(data.email)) {
            skipStep2 = Request.get(`user/validation/password-reset-allowed/${data.email}`).then(
                () => false
            ).catch(err => {
                if (err.status === 403) {
                    console.error(err);
                    // Fix the error and continue. Because user has a valid WB domain account.
                    return true;
                }

                throw err;
            });
        }

        skipStep2.then(isWBDomain => {
            let partnerId = partner.id;
            if (isWBDomain) {
                this.register(data, partnerId);
            } else {
                this.updateAttr('selectedCountry', data.country.name);
                RouterActions.redirect('/sign-up/step-two');
            }
        });
    }

    signUpStepTwo(user) {
        let data = user.toJS();

        if (data.repUserId && data.repUserId.id !== '-1') {
            data.repUserId = data.repUserId.id;
        } else {
            data.repUserId = null;
        }
        this.register(data);
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ADDITIONAL_ACCESS_UPDATE,
            attr,
            value
        });

        return;
    }

    updateInSignUp(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }

}

let actions = new SignUpActions(CONSTANTS);

export {
    actions as SignUpActions,
    CONSTANTS as SignUpConstants
};
