/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SearchConstants} from './search-actions';
import {Dispatcher} from '../flux-helpers';

class SearchStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            activePageLabel: 1,
            clips: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            displayMode: 'grid',
            displayShowSize: 60,
            documents: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            episodes: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            filters: Immutable.Map({
                talent: Immutable.List()
            }),
            images: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            publishingList: Immutable.Map({
                items: Immutable.List()
            }),
            searchSuggestions: Immutable.List(),
            searchSuggestionsQuery: '',
            talent: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            thumbnails: Immutable.Map(),
            titles: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            }),
            videos: Immutable.Map({
                loading: true,
                results: Immutable.List(),
                totalCount: 0
            })
        });
    }

    reduce(state, action) {
        let i;

        let setResults = (type) => {
            state = state.mergeIn([type], {
                downloadUrlBase: action.downloadUrlBase,
                downloadUrlQuery: action.downloadUrlQuery,
                loading: false,
                results: action.results,
                totalCount: action.totalCount
            });
        };

        switch (action.actionType) {
        case SearchConstants.CLEAR:
            state = this.getInitialState();
            break;

        case SearchConstants.FILTERS.TALENT.SUCCESS:
            state = state.setIn(['filters', 'talent'], action.talent);
            break;

        case SearchConstants.PUBLISHING_LIST.GET.SUCCESS:
            state = state.set('publishingList', action.publishingList);
            break;

        case SearchConstants.SEARCH.CLIPS.ERROR:
            state = state.setIn(['clips', 'loading'], false);
            break;

        case SearchConstants.SEARCH.CLIPS.SUCCESS:
            setResults('clips');
            break;

        case SearchConstants.SEARCH.DOCUMENTS.ERROR:
            state = state.setIn(['documents', 'loading'], false);
            break;

        case SearchConstants.SEARCH.DOCUMENTS.SUCCESS:
            setResults('documents');
            break;

        case SearchConstants.SEARCH.EPISODES.ERROR:
            state = state.setIn(['episodes', 'loading'], false);
            break;

        case SearchConstants.SEARCH.EPISODES.SUCCESS:
            setResults('episodes');
            break;

        case SearchConstants.SEARCH.IMAGES.ERROR:
            state = state.setIn(['images', 'loading'], false);
            break;

        case SearchConstants.SEARCH.IMAGES.SUCCESS:
            setResults('images');
            break;

        case SearchConstants.SEARCH.TALENT.ERROR:
            state = state.setIn(['talent', 'loading'], false);
            break;

        case SearchConstants.SEARCH.TALENT.SUCCESS:
            setResults('talent');
            break;

        case SearchConstants.SEARCH.THUMBNAILS.SUCCESS:
            state = state.update('thumbnails', thumbnails => thumbnails.merge(action.thumbnails));
            break;

        case SearchConstants.SEARCH.TITLES.ERROR:
            state = state.setIn(['titles', 'loading'], false);
            break;

        case SearchConstants.SEARCH.TITLES.SUCCESS:
            setResults('titles');
            break;

        case SearchConstants.SEARCH.VIDEOS.ERROR:
            state = state.setIn(['videos', 'loading'], false);
            break;

        case SearchConstants.SEARCH.VIDEOS.SUCCESS:
            setResults('videos');
            break;

        case SearchConstants.SET.DISPLAY_MODE:
            state = state.set('displayMode', action.mode);
            break;

        case SearchConstants.SET.DOCUMENT.IMAGE_ID:
            // Find the document by id.
            i = state.getIn(['documents', 'results']).findIndex(t => t.get('id') === action.documentId);
            state = state.setIn(['documents', 'results', i, 'defaultImagePortraitId'], action.imageId);
            break;

        case SearchConstants.SET.TALENT.AKA:
            // Find the talent by id.
            i = state.getIn(['talent', 'results']).findIndex(t => t.get('talentId') === action.talentId);
            state = state.setIn(['talent', 'results', i, 'aka'], action.aka);
            break;

        case SearchConstants.SET.TALENT.IMAGE_ID:
            // Find the talent by id.
            i = state.getIn(['talent', 'results']).findIndex(t => t.get('talentId') === action.talentId);
            state = state.setIn(['talent', 'results', i, 'defaultImagePortraitId'], action.imageId);
            break;

        case SearchConstants.SET.TITLE.DETAILS:
            // Set additional details on title (used for list view)
            ['titles', 'episodes'].forEach(c => {
                let titleIndex = state.getIn([c, 'results']).findIndex(t => t.get('id') === action.id);
                if (titleIndex !== -1) {
                    state = state.mergeIn([c, 'results', titleIndex], {
                        // TODO: review this logic, there are a lot of things
                        // that already exist in the title result now!
                        details: action.title,
                        genres: action.genres,
                        languages: action.languages,
                        seasonCount: action.seasons.size
                    });
                }
            });
            break;

        case SearchConstants.SUGGESTIONS.CLEAR:
            state = state.set('searchSuggestions', Immutable.List());
            break;
        case SearchConstants.SUGGESTIONS.GET.SUCCESS:
            state = state.merge({
                searchSuggestions: action.searchSuggestions,
                searchSuggestionsQuery: action.query
            });
            break;
        }

        return state;
    }
}

let store = new SearchStore(Dispatcher);
export default store;
