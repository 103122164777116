/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../../flux-helpers';
import {NotificationActions} from '../../../notification/notification-actions';
import Request from '../../../request';

const CONSTANTS = {
    CLEAR: 'downloadHistory.clear',
    ASSET: {
        GET: {
            ERROR: 'download-history.asset.get.error',
            START: 'download-history.asset.get.start',
            SUCCESS: 'download-history.asset.get.success',
        }
    },
    GET: {
        ERROR: 'download-history.get.error',
        START: 'download-history.get.start',
        SUCCESS: 'download-history.get.success',
    }
};

class DownloadHistoryActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getDownloadHistory(userId, pageSize, offset) {
        if (!userId) {return;}

        let query = {
            offset: offset || 0,
            size: pageSize || 5,
            type: 'WEB',
            'object-name': 'DOWNLOADCONTENT'
        };

        Dispatcher.dispatch({actionType: CONSTANTS.GET.START, query: Immutable.fromJS(query)});

        return Request.get(`user/${userId}/action-history`).query(query).then(resp => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset: resp.body.offset,
                size: resp.body.size,
                history: Immutable.fromJS(resp.body.results),
                totalCount: resp.body.totalCount
            });

            // Load details for assets
            return this.getAssets(resp.body.results.map(c=>{return c.objectId;}));
        }).catch(err => {
            Dispatcher.dispatch({actionType: CONSTANTS.GET.ERROR, error: err});
            NotificationActions.showAlertDanger('account.tab.download-history.load-history-error');
            throw err;
        });
    }

    getAssets(assetIds) {
        // Passed an array of assetIds
        if (assetIds && assetIds.length) {
            Dispatcher.dispatch({actionType: CONSTANTS.ASSET.GET.START});

            return assetIds.map(id => {
                return Request.get(`asset/${id}`)
                    .then(asset => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.ASSET.GET.SUCCESS,
                            asset: Immutable.fromJS(asset.body)
                        });
                    }).catch(err => {
                        Dispatcher.dispatch({actionType: CONSTANTS.ASSET.GET.ERROR, error: err});
                        throw err;
                    });
            });
        }
    }
}

let actions = new DownloadHistoryActions();

export {
    actions as DownloadHistoryActions,
    CONSTANTS as DownloadHistoryConstants,
};
