/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';

const CONSTANTS = {
    GET: {
        SUCCESS: 'footer_actions.get.success'
    },
    PUBLISH_LISTS: {
        ABOUT: 0,
        CATALOGS: 1,
        INTERNAL: 3,
        TOOLS: 2
    }
};

class FooterActions {
    get() {
        // Fetch publish lists by ids
        [CONSTANTS.PUBLISH_LISTS.ABOUT, CONSTANTS.PUBLISH_LISTS.CATALOGS, CONSTANTS.PUBLISH_LISTS.INTERNAL, CONSTANTS.PUBLISH_LISTS.TOOLS].forEach(
            id => Request.get(`web/publish-list/${id}`).then(plRes => {
                let list = plRes.body;
                if (!list) {
                    return;
                }
                return Request.get(`web/publish-list/${list.id}/my-items`).then(itemsRes => {
                    // Add the items to the publishing list.
                    list.items = itemsRes.body.sort((a, b) => a.displayOrder - b.displayOrder);
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.GET.SUCCESS,
                        list: Immutable.fromJS(list),
                        listName: list.name.toLowerCase()
                    });
                });
            }).catch(err => {
                throw err;
            })
        );
        return;
    }
}

let actions = new FooterActions();

export {
    actions as FooterActions,
    CONSTANTS as FooterConstants
};
