/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import Request from '../request';

export default class LookupActions {

    constructor(path, CONSTS) {
        this.CONSTANTS = CONSTS;
        this.path = path;
    }

    get(offset, size) {
        Dispatcher.dispatch({
            actionType: this.CONSTANTS.GET.START
        });

        Request.get(this.path)
            .query({
                offset: offset,
                size: size
            })
            .then(res => {
                // Some API's return pagination objects,
                // some other return plain arrays.
                let items = [];
                switch (true) {

                case Array.isArray(res.body):
                    items = res.body;
                    offset = 0;
                    size = res.body.length;
                    break;
                case Array.isArray(res.body.results):
                    items = res.body.results;
                    offset = res.body.offset;
                    size = res.body.size;
                    break;
                }

                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.SUCCESS,
                    offset: offset,
                    items: Immutable.fromJS(items).sortBy(this.sortItemsBy),
                    size: size,
                    total: parseInt(res.header['wbtv-total-count'], 10)
                });
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.ERROR
                });
                throw err;
            });

        return;
    }

    /**
     *  If needed, any other action-creator can override this
     *  function.
     */
    sortItemsBy(item) {
        return item.get('name');
    }
}
