/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../../flux-helpers';
import {NotificationActions} from '../../../notification/notification-actions';
import Request from '../../../request';

const CONSTANTS = {
    GET: {
        ERROR: 'saved-searches.get.error',
        START: 'saved-searches.get.start',
        SUCCESS: 'saved-searches.get.success',
    }
};

class SavedSearchesActions {

    getSavedSearches(userId) {
        Dispatcher.dispatch({actionType: CONSTANTS.GET.START});

        Request.get(`user/${userId}/saved-search`).then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                searches: Immutable.fromJS(response.body)
            });

        }).catch(err => {
            Dispatcher.dispatch({actionType: CONSTANTS.GET.ERROR});
            NotificationActions.showAlertDanger('account.tab.my-saved-searches.load-error');
            throw err;
        });
    }

    shareSearch(email, userId, savedSearchId) {
        Request.post(`user/${userId}/saved-search/${savedSearchId}/share`)
            .send({
                emailAddress: email,
            })
            .then(() => {
                NotificationActions.showAlertSuccess('search.share-search.success');
            })
            .catch(err => {
                NotificationActions.showAlertDanger('search.share-search.error');
                throw err;
            });
    }

    deleteSavedSearch(userId, savedSearchData) {
        Request.del(`user/${userId}/saved-search/${savedSearchData.shareId}`)
            .then(() => {
                NotificationActions.showAlertSuccess('account.tab.my-saved-searches.delete-saved-search.success');
                this.getSavedSearches(userId);
            })
            .catch(err => {
                NotificationActions.showAlertDanger('account.tab.my-saved-searches.delete-saved-search.error');
                throw err;
            });
    }
}

let actions = new SavedSearchesActions();

export {
    actions as SavedSearchesActions,
    CONSTANTS as SavedSearchesConstants,
};
