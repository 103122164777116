/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {SupportConstants} from './support-actions';
import {Dispatcher} from '../../flux-helpers';

class SupportStore extends ReduceStore {

    getInitialState() {
        return Immutable.Map({
            supportRequest: Immutable.Map(),
            showSupportModal: false,
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case SupportConstants.SUPPORT.CLEAR:
            state = this.getInitialState();
            break;

        case SupportConstants.SUPPORT.SHOW_SUPPORT_MODAL:
            state = state.set('showSupportModal', action.flag);
            break;

        case SupportConstants.SUPPORT.UPDATE_ATTR:
            state = state.setIn(['supportRequest', ...action.attr.split('.')], action.value);
            break;
        }

        return state;
    }
}

export default new SupportStore(Dispatcher);
