/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';
import LookupActions from '../lookup-actions';

const CONSTANTS = {
    GET: {
        ERROR: 'territory_actions.get.error',
        START: 'territory_actions.get.start',
        SUCCESS: 'territory_actions.get.success'
    },
    MESSAGES: {
        CREATE_ERROR: 'lookups.territory.create.error',
        CREATE_OK: 'lookups.territory.create.ok',
        REMOVE_ERROR: 'lookups.territory.remove.error',
        REMOVE_OK: 'lookups.territory.remove.ok',
        SAVE_ERROR: 'lookups.territory.save.error',
        SAVE_OK: 'lookups.territory.save.ok',
    },
    REMOVE_OK: 'territory_actions.remove',
    SAVE_OK: 'territory_actions.save',
};

class TerritoryActions extends LookupActions {

    constructor(path, CONSTS) {
        super(path, CONSTS);
    }

    get(offset, size) {
        Dispatcher.dispatch({
            actionType: this.CONSTANTS.GET.START
        });

        Request.get(this.path)
            .query({
                offset: offset,
                size: size
            })
            .then(res => {
                let items = res.body.results;
                offset = res.body.offset;
                size = res.body.size;

                let usCountry;
                items = items.filter((item) => {
                    if (item.name.toLowerCase() === 'u.s.' && items[0] !== item) {
                        usCountry = item;
                        return false;
                    }
                    return true;
                });

                items = Immutable.fromJS(items).sortBy(this.sortItemsBy);
                items = items.unshift(Immutable.fromJS(usCountry));

                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.SUCCESS,
                    offset: offset,
                    items: items,
                    size: size,
                    total: parseInt(res.header['wbtv-total-count'], 10)
                });
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: this.CONSTANTS.GET.ERROR
                });
                throw err;
            });

        return;
    }
}

let actions = new TerritoryActions('lookup/territory', CONSTANTS);

export {
    actions as TerritoryActions,
    CONSTANTS as TerritoryConstants
};
